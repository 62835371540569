// @flow

import { CHANGE_DATE } from './actionTypes'
import type { State, Action } from './types'

const initialState: State = moment(new Date())

export const reducer = (state: State = initialState, { type, payload }: Action): State => {
  switch (type) {
    case CHANGE_DATE:
      return moment(new Date(payload))

    default:
      return state
  }
}
