// @flow
import { StyleSheet } from 'aphrodite/no-important'

import { colors } from 'constants'

export default StyleSheet.create({
  main: {
    outline: 'none',
    display: 'block',
    paddingTop: 12,
    paddingBottom: 16,
    boxShadow: 'inset 0 -1px 0 0 #F1F2F2',
    marginLeft: -25,
    width: 'calc(100% + 50px)',
  },
  'main--preview': { paddingTop: 0 },
  'main--control': {
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    margin: 0,
    cursor: 'pointer',
    boxShadow: 'none',
    // borderLeft: '4px solid #eaeff3',
    paddingTop: 11,
    paddingBottom: 20,
  },
  'main--control-active': {
    backgroundColor: '#247b7b',
  },
  preview: {
    height: 180,
    marginBottom: -12,
  },
  image: {
    marginLeft: 25,
    marginRight: 25,
    width: 24,
    height: 24,
    border: '3px solid #fff',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  preline: {
    opacity: 0.75,
    fontWeight: 300,
    fontSize: 14,
    color: colors.white,
    lineHeight: '20px',
    margin: '0 25px',
  },
  headline: {
    margin: '10px 25px',
    fontWeight: 300,
    fontSize: 20,
    color: colors.darkFont,
  },
  headlineSmall: {
    margin: '0 25px',
    lineHeight: '22px',
    color: colors.white,
  },
  text: {
    marginLeft: 25,
    marginRight: 25,
    opacity: 0.75,
    fontWeight: 400,
    fontSize: 14,
    color: '#062033',
    lineHeight: '20px',
  },
  textSmall: {
    maxHeight: 42,
    lineHeight: '20px',
    overflow: 'hidden',
    color: colors.white,
  },
  textLink: {
    marginLeft: 5,
    color: '#133a62',
    borderBottom: '1px solid #133a62',
  },
})
