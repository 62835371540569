// @flow
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { css } from 'aphrodite/no-important'
import { parse } from 'qs'
import Cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import checkUrl from 'utils/checkUrl'
import Button from 'components/Button'
import Footer from 'components/Footer'
import User from 'components/_icons/User'
import Lock from 'components/_icons/Lock'

import validateUserToken from 'modules/auth/validate/actions'
import { selectUserData, selectUserError } from 'modules/auth/login/selectors'
import fetchUserToken from 'modules/auth/login/actions'

import styles from './style'
import './style.css'
import Input from '../../components/Input'

import type { Props } from './types'

class Login extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      mail: '',
      password: '',
    }

    this.handleLogin = this.handleLogin.bind(this)
  }

  async handleLogin(e): React$Node {
    e.preventDefault()
    this.handleCookies()
    const { redirectTo = '/' } = parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })

    try {
      this.setState({ loading: true })
      const resp = await this.props.fetchUserToken(this.state.mail, this.state.password)

      this.setState({ loading: false })

      if (resp.response.token !== '') {
        await this.props.validateUserToken()
        window.location.href = redirectTo
      }
    } catch (e) {
      Cookies.remove('token')
      this.setState({ loading: false })
    }
  }

  componentDidMount() {
    const token = Cookies.get('token')
    const { exp } = typeof token !== 'undefined' && jwt.decode(token)
    const today = Math.round(new Date().getTime() / 1000)

    if (token === null) {
    } else if (token && exp > today) {
      window.location.href = '/'
    } else {
      Cookies.remove('token')
      console.error('Will not redirect, since token is expired.')
    }
  }

  async handleCookies(e) {
    await fetch(`${checkUrl()}/wp-login.php`, {
      method: 'POST',
      body: `log=${encodeURIComponent(this.state.mail)}&pwd=${encodeURIComponent(
        this.state.password
      )}`,
      credentials: 'same-origin',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        Cache: 'no-cache',
      },
    })
      .then(response => console.log('DONE'))
      .catch(err => console.log(err))
  }

  render(): React$Element<'div'> {
    return (
      <div className={css(styles.loginPage)}>
        <div className="container">
          <h1>Login</h1>

          <div className="row">
            <form onSubmit={e => this.handleLogin(e)} className="col-sm-4">
              {this.props.userError.msg && this.props.userError.msg !== 'Wrong number of segments' && (
                <div
                  className={css(styles.loginPage__error)}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: this.props.userError.msg,
                  }}
                />
              )}
              <Input
                icon={User}
                placeholder="Username"
                onChange={e => this.setState({ mail: e.target.value })}
              />
              <Input
                type="password"
                icon={Lock}
                placeholder="Passwort"
                onChange={e => this.setState({ password: e.target.value })}
              />
              <Button loading={this.state.loading} type="submit" block>
                Einloggen
              </Button>
            </form>
            <div className="col-sm-7">
              <div className="row">
                <div className="col-sm-6">
                  <h4>Passwort vergessen</h4>
                  <p>
                    Sollten Sie Ihr Passwort vergessen haben, können Sie dieses über nachfolgenden
                    Link zurücksetzen.
                    <br />
                    <br />
                  </p>
                  <br />

                  <Button to="https://selfservice.ovb.eu">Passwort zurücksetzen</Button>
                </div>
                <div className="col-sm-6">
                  <h4>OVB IT-Support</h4>
                  <p>
                    Damit sich die IT der OVB auf Ihren PC aufwählen kann und Ihnen gezielte
                    Hilfestellung leisten kann, laden Sie sich bitte den Teamviewer herunter.
                  </p>
                  <br />

                  <Button to="https://portal.ovb.de/wp-content/uploads/2024/02/TeamViewerQS.exe-14-.zip">
                    TeamViewer herunterladen
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer onSmallPage />
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: selectUserData(state),
      userError: selectUserError(state),
    }),
    { fetchUserToken, validateUserToken }
  )(Login)
)
