// @flow

import { CALL_API } from 'utils/callApi'

import {
  LOAD_EVENTS_REQUEST,
  LOAD_EVENTS_SUCCESS,
  LOAD_EVENTS_FAILURE,
  LOAD_EVENTSPAGE_REQUEST,
  LOAD_EVENTSPAGE_SUCCESS,
  LOAD_EVENTSPAGE_FAILURE,
} from './actionTypes'

export function loadEvents(): * {
  return {
    [CALL_API]: {
      endpoint: '/wp/v2/events?per_page=100',
      types: [LOAD_EVENTS_REQUEST, LOAD_EVENTS_SUCCESS, LOAD_EVENTS_FAILURE],
    },
  }
}

export function loadEventsPage(num): * {
  return {
    [CALL_API]: {
      endpoint: `/wp/v2/events?per_page=100&page=${num}`,
      types: [LOAD_EVENTSPAGE_REQUEST, LOAD_EVENTSPAGE_SUCCESS, LOAD_EVENTSPAGE_FAILURE],
    },
  }
}
