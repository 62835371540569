// @flow
import { StyleSheet } from 'aphrodite/no-important'

import { TABLET } from 'constants/breakpoints'

export default StyleSheet.create({
  main: {
    textAlign: 'left',
    paddingTop: 65,
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '30px 0 20px',
  },
  sidebarHolder: {
    [TABLET]: {
      marginBottom: 24,
    },
  },
})
