// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  wrapper: {},
  inner: {
    margin: '0 8px',
    paddingTop: 10,
    background: 'rgba(255,255,255, 0.1)',
    fontSize: '35px',
    height: '80px',
    lineHeight: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
  },
  dayName: {
    marginTop: 10,
    fontSize: '16px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#FFFFFF',
  },
})
