// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  bar: {
    marginTop: 40,
    marginBottom: 45,
  },
  mainHolder: {
    padding: '100px 0 55px',
  },
  textlink: {
    fontSize: 16,
    color: '#2771C6',
    lineHeight: '34px',
    display: 'block',
  },
  sidebarLink: {
    fontSize: 16,
    color: '#000 !important',
    lineHeight: '32px',
    display: 'block',
    cursor: 'pointer',
    ':hover': {
      padding: '2px',
      background: '#0B3A65',
      color: '#fff',
    },
    ':hover *': {
      background: '#0B3A65',
      color: '#fff !important',
    },
  },
  videoHolder: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
})
