import React from "react";
import { Teaser } from "./Teaser";
import { ContentBlock } from "./ContentBlock";
import { CustomSlider as Slider } from "./Slider";
import { Downloads } from "./Downloads";
import { Accordions } from "./Accordions";
import { Ansprechpartner } from "./Ansprechpartner";
import { ContentGrid } from "./ContentGrid";
import { UserStories } from "./UserStories";
import { Formular } from "./Formular";


export class Provider extends React.Component {
  returnItem = (item) => {
    switch (item.acfFcLayout) {
      case "teaser":
        return <Teaser data={item} />;
      case "content_block":
        return <ContentBlock data={item} />;
      case "slider":
        return <Slider data={item} />;
      case "downloads":
        return <Downloads data={item} />;
      case "accordions":
        return <Accordions data={item} />;
      case "ansprechpartner":
        return <Ansprechpartner data={item} />;
      case "content_grid":
        return <ContentGrid data={item} />;
      case "user_stories":
        return <UserStories data={item} />;
      case "formular":
        return <Formular data={item} />;

      default:
        break;
    }
  };

  render() {
    return (
      <div className="fceElemente" style={{ width: "100%" }}>
        {this.props.data[0] &&
          this.props.data[0].acf.flexibleContentElemente.map((item) =>
            this.returnItem(item)
          )}
      </div>
    );
  }
}
