// @flow

export default function functionality(evt) {
  const accordions = [].slice.call(document.querySelectorAll('.accordions-head'))
  const clickedAnAccordion = accordions.some(acc => acc.contains(evt.target) || evt.target === acc)
  const href = evt.target.getAttribute('href')

  if (href && href.indexOf('#') === 0) {
    evt.preventDefault()

    document.querySelector('.tabs-nav a.active').removeAttribute('class')
    document.querySelector('section.tab.active').className = 'tab'

    evt.target.className = 'active' // eslint-disable-line
    document.querySelector(href).className = 'tab active'
  } else if (clickedAnAccordion) {
    const clickedAccordions = accordions
      .map(a => (a.contains(evt.target) || evt.target === a ? a : false))
      .filter(a => a)

    clickedAccordions.forEach(accordion => {
      const { parentNode, nextSibling } = accordion

      if (accordion.classList.contains('active')) {
        // Remove the `active` classes from all accordions
        const currentHead = parentNode.querySelector('.accordions-head.active')
        const currentContent = parentNode.querySelector('.accordion-content.active')

        if (currentHead) currentHead.classList.remove('active')
        if (currentContent) currentContent.classList.remove('active')

        return
      }

      // Remove the `active` classes from all accordions
      const currentHead = parentNode.querySelector('.accordions-head.active')
      const currentContent = parentNode.querySelector('.accordion-content.active')

      if (currentHead) currentHead.classList.remove('active')
      if (currentContent) currentContent.classList.remove('active')

      // Add the `active` class to the active accordion
      accordion.classList.add('active')
      nextSibling.classList.add('active')
    })
  }
}
