// @flow
import { combineReducers } from 'redux'

import { reducer as dateReducer } from './date'
import { reducer as eventsReducer } from './events'

export const reducer = combineReducers({
  date: dateReducer,
  events: eventsReducer,
})
