// @flow
import { createSelector } from 'reselect'
import type { Selector } from 'reselect'
import type { State as RootState } from '../types'
import type { State, Documents } from './types'

export const selectDocumentsTree = (state: RootState): State => state.documents

export const selectLoaded: Selector<*, *, boolean> = createSelector(
  selectDocumentsTree,
  tree => tree.loaded
)
export const selectDocuments: Selector<*, *, Documents> = createSelector(
  selectDocumentsTree,
  tree => tree.data
)

export const selectDocumentsPages: Selector<*, *, Documents> = createSelector(
  selectDocumentsTree,
  tree => tree.documentsByPage
)
