// @flow
import React, { Component, Fragment } from 'react' // eslint-disable-line no-unused-vars
import { connect } from 'react-redux'
import { orderBy } from 'lodash'
import { css } from 'aphrodite/no-important'
import dateFormat from 'dateformat' // eslint-disable-line no-unused-vars
import { isBefore } from 'date-fns'

import { selectEvents } from 'modules/calendar/events/selectors'
import UpDownUi from '../UpDownUi'
import { convertTime } from '../../_modules/EventWeek/helper'

import styles from './style'

import type { Props } from './types'
import { downloadFile } from '../../_modules/EventWeek/utils'

const today = new Date()

function save(item) {
  downloadFile(item)
}

const TableRow = (item: Object, index: number, isMasthead: ?boolean): React$Node =>
  isBefore(today, new Date(item.acf.datum)) && (
    <tr
      key={Math.random().toString() + index}
      className={css(styles.tr, !!(index % 2) && styles.tr__even)}
    >
      <td className={css(styles.td)}>{dateFormat(new Date(item.acf.datum), 'dd.mm.yyyy')}</td>
      <td className={css(styles.td, styles.th__name)} colSpan={2}>
        <a className={css(styles.link)}>{item.title.rendered}</a>
      </td>
      <td className={css(styles.td)}>
        <a className={css(styles.link)}>{item.acf.ort}</a>
      </td>
      {!isMasthead && (
        <Fragment>
          <td className={css(styles.td)}>{item.acf.start && convertTime(item.acf.start)}</td>
          <td className={css(styles.td)}>{item.acf.end && convertTime(item.acf.end)}</td>
          <td className={css(styles.td)}>
            {item.acf.link && (
              <a
                className={css(styles.link)}
                href={item.acf.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Beitreten
              </a>
            )}
          </td>
        </Fragment>
      )}
      <td className={css(styles.td)}>
        <a onClick={() => save(item)} className={css(styles.link, styles.download)}>
          <svg
            className={css(styles.download__svg)}
            width="18"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 2h-1V0h-2v2H5V0H3v2H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 5c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H3v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1z"
              fill="#2771C6"
              fillRule="nonzero"
            />
          </svg>
          Speichern
        </a>
      </td>
    </tr>
  )

class TableEvents extends Component<Props, void> {
  state = { data: this.props.data, sortBy: 'acf.datum,asc' }

  componentWillUpdate(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.handleData(nextProps.data)
    }
  }

  handleData = data => this.setState({ data })

  handleSort = sortBy => {
    this.setState({ sortBy })
  }

  render(): React$Element<'table'> {
    let { data } = this.state

    if (this.props.filter) {
      data = data.filter(item => item.title.rendered.indexOf(this.props.filter) !== -1)
    }

    if (this.state.sortBy) {
      const [sortBy, direction] = this.state.sortBy.split(',')
      data = orderBy(data, [sortBy], [direction])
    }

    return (
      <table className={css(styles.table)}>
        {this.props.headline && (
          <thead>
            <tr>
              <th className={css(styles.th__heading)} colSpan={this.props.isMasthead ? 4 : 7}>
                {this.props.headline}{' '}
                <span className={css(styles.th__heading__Small)}>{this.props.subline}</span>
              </th>
            </tr>
          </thead>
        )}

        <tbody>
          {!this.props.isMasthead && (
            <tr className={css(styles.tr)}>
              <th className={css(styles.th__td, styles.th__date)}>
                <div className={css(styles.td__inner)}>
                  Datum{' '}
                  <UpDownUi
                    onUpClick={() => this.handleSort('acf.datum,asc')}
                    onDownClick={() => this.handleSort('acf.datum,desc')}
                  />
                </div>
              </th>
              <th className={css(styles.th__td, styles.th__name)} colSpan={2}>
                <div className={css(styles.td__inner)}>
                  Name{' '}
                  <UpDownUi
                    onUpClick={() => this.handleSort('title.rendered,asc')}
                    onDownClick={() => this.handleSort('title.rendered,desc')}
                  />
                </div>
              </th>
              <th className={css(styles.th__td, styles.th__from)}>
                <div className={css(styles.td__inner)}>
                  Ort{' '}
                  <UpDownUi
                    onUpClick={() => this.handleSort('acf.ort,asc')}
                    onDownClick={() => this.handleSort('acf.ort,desc')}
                  />
                </div>
              </th>
              <th className={css(styles.th__td, styles.th__from)}>
                <div className={css(styles.td__inner)}>
                  Von{' '}
                  <UpDownUi
                    onUpClick={() => this.handleSort('acf.start,asc')}
                    onDownClick={() => this.handleSort('acf.start,desc')}
                  />
                </div>
              </th>
              <th className={css(styles.th__td, styles.th__to)}>
                <div className={css(styles.td__inner)}>
                  Bis{' '}
                  <UpDownUi
                    onUpClick={() => this.handleSort('acf.end,asc')}
                    onDownClick={() => this.handleSort('acf.end,desc')}
                  />
                </div>
              </th>
              <th className={css(styles.th__td, styles.th__category)}>
                <div className={css(styles.td__inner)}>Link </div>
              </th>
              <th className={css(styles.th__td, styles.th__date)}>
                <div className={css(styles.td__inner)}>Aktion </div>
              </th>
            </tr>
          )}

          {data &&
            data.map((item, index) => {
              if (isBefore(today, new Date(item.acf.datum))) {
                return TableRow(item, index, this.props.isMasthead)
              }
            })}
        </tbody>
      </table>
    )
  }
}

export default connect(
  state => ({
    data: selectEvents(state),
  }),
  null
)(TableEvents)
