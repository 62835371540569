// @flow
import { StyleSheet } from 'aphrodite/no-important'

import { TABLET } from 'constants/breakpoints'

export default StyleSheet.create({
  main: {
    background: '#f6f7f8',
  },
  boxHolder: {
    display: 'flex',
    [TABLET]: {
      flexDirection: 'column',
    },
  },
})
