// @flow

import { CALL_API } from 'utils/callApi'
import { LOAD_LINKS_REQUEST, LOAD_LINKS_SUCCESS, LOAD_LINKS_FAILURE } from './actionTypes'

export default function loadLinks(): * {
  return {
    [CALL_API]: {
      endpoint: `/wp/v2/wichtige_links?per_page=100`,
      types: [LOAD_LINKS_REQUEST, LOAD_LINKS_SUCCESS, LOAD_LINKS_FAILURE],
    },
  }
}
