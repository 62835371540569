// @flow
import { createSelector } from 'reselect'
import type { State as RootState } from '../types'
import type { State } from './types'

export const selectMenuTree = (state: RootState): State => state.menu

export const selectMenuData = createSelector(selectMenuTree, tree => tree.data)
export const selectMenuItems = createSelector(selectMenuData, tree => tree.items)

export const selectTopLevelMenuItems = createSelector(
  selectMenuItems,
  tree => tree.menu_item_parent === '0'
)

export const selectMenuLoaded = createSelector(selectMenuTree, tree => tree.loaded)
