// @flow
import React from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { css } from 'aphrodite/no-important'

import Footer from 'components/Footer'
import { selectCurrentDate } from 'modules/calendar/date/selectors'
import { selectEventsForDate } from 'modules/calendar/events/selectors'

import Navigation from './components/Navigation'
import Cart from './components/Cart'
import SuggestedItems from './components/SuggestedItems'
import Totals from './components/Totals'
import Banner from './components/Banner'

import styles from './style'

function CartPage(): React$Node {
  return (
    <div className={css(styles.main)}>
      <Helmet title="Ihr Warenkorb" />

      <div className="container">
        <Navigation />
        <Banner />
        <Cart />
        <Totals />

        <SuggestedItems />
      </div>

      <Footer />
    </div>
  )
}

export default connect(
  state => ({
    date: selectCurrentDate(state),
    events: selectEventsForDate(state),
  }),
  null
)(CartPage)
