// @flow

import { StyleSheet } from 'aphrodite/no-important'
import { white, primary } from 'constants/color'

import { TABLET, DESKTOP } from 'constants/breakpoints'

export default StyleSheet.create({
  info__wrapper: {
    padding: '30px 0',
    background: white,
    textAlign: 'left',
    marginTop: 80,
    borderTop: `1px solid #d5dfe5`,
  },
  headline: {
    fontWeight: 600,
    fontSize: 20,
  },
  link: {
    fontWeight: 300,
    fontSize: 14,
    lineHeight: '27px',
    display: 'block',
  },
  icon: {
    borderRadius: 8,
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 15,
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 200,
    margin: '5px 0',
  },
  flex__left: {
    justifyContent: 'flex-start',
  },
  type: {
    opacity: 0.75,
    fontSize: 14,
    lineHeight: '20px',
  },
  type__left: {
    minWidth: 70,
  },
  info: {
    fontSize: 14,
    lineHeight: '20px',
  },
  info__left: {
    textAlign: 'left',
  },
  info__link: {
    color: '#000',
  },
  social: {
    [DESKTOP]: {
      marginTop: 20,
    },
    [TABLET]: {
      marginBottom: 20,
    },
  },
  bold: {
    color: '#000',
    fontWeight: '600',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    marginRight: 25,
    [TABLET]: {
      marginBottom: 20,
    },
  },
})
