import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { css } from 'aphrodite/no-important'

import Headline from 'components/Box/Headline'
import { Button } from 'components'
import { selectCurrentDate } from 'modules/calendar/date/selectors'
import {
  selectEvents,
  selectEventsForDateSorted,
  selectEventPageLoading,
} from 'modules/calendar/events/selectors'
import { loadEvents, loadEventsPage } from 'modules/calendar/events/actions'

import PlusIcon from './icons/Plus'
import ArrowIcon from './icons/Arrow'
import { dayNames, monthNames, convertTime } from './helper'
import { downloadFile } from './utils'
import styles from './styles'

// eslint-disable-next-line flowtype/require-return-type
const EventWeek = props => {
  const [week, setWeek] = useState([])
  const [doneOnce, setDoneOnce] = useState(false)
  const [initialLoad, setInitialLoad] = useState(false)

  useEffect(() => {
    if (!doneOnce && props.allEvents.length === 0) {
      props.loadEvents()
      setDoneOnce(true)
    }
  })

  useEffect(() => {
    if (props.allEventslength !== 0) {
      const length = props.allEvents.length
      const lengthStr = props.allEvents.length.toString()
      const val = lengthStr.slice(-2)
      const num = lengthStr.slice(0, 1)
      if (val === '00' && !props.pageLoading) props.loadEventsPage(parseInt(num) + 1)
    }
  }, [props.allEvents])

  const today = new Date()
  const currentDateString = today.toISOString().slice(0, 10)

  useEffect(() => {
    if (!initialLoad) {
      const newWeek = []
      for (let i = 0; i <= 6; i++) {
        const now = new Date()
        const theDay = now.getDate() + i
        const day = new Date(now.setDate(theDay)).toISOString().slice(0, 10)
        newWeek.push(day)
      }

      setWeek(newWeek)
      setInitialLoad(true)
    }
  }, [initialLoad])

  // eslint-disable-next-line flowtype/require-return-type
  const getEventsPerDay = day => {
    const data = props.allEvents.filter(item => item.acf.datum === day)
    const unique = data.filter(
      (thing, index, self) => index === self.findIndex(t => t.id === thing.id)
    )

    return unique.sort((a, b) => {
      if (a.acf.start && b.acf.start) {
        const aStart = convertTime(a.acf.start)
        const bStart = convertTime(b.acf.start)
        return aStart.localeCompare(bStart)
      }
      return 0
    })
  }

  // eslint-disable-next-line flowtype/require-return-type
  const formatDate = day => {
    const d = new Date(day)
    const year = d.getFullYear()
    const month = monthNames[new Date(day).getMonth()]
    const date = d.getDate()
    return `${date}. ${month} ${year}`
  }

  // eslint-disable-next-line flowtype/require-return-type
  const getDateRange = () => {
    const start = new Date(week[0])
    const end = new Date(week[6])
    const month = monthNames[end.getMonth()]

    return `${start.getDate()}. - ${end.getDate()}. ${month}`
  }

  const handleDownload = item => downloadFile(item)

  const nextWeek = () => {
    const lastDate = week[week.length - 1]
    setWeek([])
    const newWeek = []

    for (let i = 1; i <= 7; i++) {
      const now = new Date(lastDate)
      const theDay = now.getDate() + i
      const day = new Date(now.setDate(theDay)).toISOString().slice(0, 10)
      newWeek.push(day)
    }

    setWeek(newWeek)
  }

  const prevWeek = () => {
    const firstDate = week[0]
    setWeek([])
    const newWeek = []

    for (let i = 1; i <= 7; i++) {
      const now = new Date(firstDate)
      const theDay = now.getDate() - 8 + i
      const day = new Date(now.setDate(theDay)).toISOString().slice(0, 10)
      newWeek.push(day)
    }

    setWeek(newWeek)
  }

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.flex)}>
        <Headline isBig headline={`Events vom ${getDateRange()}`} />
        <div className={css(styles.flex, styles.flexCenter)}>
          <div className={css(styles.navBtns)} onClick={() => prevWeek()}>{`<`}</div>
          <div className={css(styles.navBtns)} onClick={() => nextWeek()}>{`>`}</div>
          <Button to="/veranstaltungen">Zum Kalender</Button>
        </div>
      </div>
      <div className={css(styles.overflow)}>
        <div className={css(styles.calendar)}>
          {week.map((day, i) => (
            <div
              key={JSON.stringify(i)}
              className={css(styles.day, day === currentDateString && styles['day--active'])}
            >
              <div className={css(styles.dayTop)}>
                <div className={css(styles.dayHeadline, day === currentDateString && styles.white)}>
                  {dayNames[new Date(day).getDay()]}
                </div>
                <div className={css(styles.daySubline, day === currentDateString && styles.white)}>
                  {formatDate(day)}
                </div>
              </div>
              <div className={css(styles.dayEventList)}>
                {!getEventsPerDay(day).length && (
                  <div className={css(styles.event)}>
                    <div
                      className={css(styles.eventTime, day === currentDateString && styles.white)}
                    >
                      Keine Termine
                    </div>
                  </div>
                )}
                {getEventsPerDay(day).map((item, index) => (
                  <>
                    <div key={JSON.stringify(index)} className={css(styles.event)}>
                      <div
                        className={css(styles.eventTime, day === currentDateString && styles.white)}
                      >
                        {item.acf.start && convertTime(item.acf.start)}
                        <div className={css(styles.icons)}>
                          <PlusIcon
                            onClick={() => handleDownload(item)}
                            color="#003A65"
                            bg="#DCE4EA"
                          />
                          {item.acf.link && (
                            <ArrowIcon link={item.acf.link} color="#003A65" bg="#DCE4EA" />
                          )}
                        </div>
                      </div>
                      <div
                        className={css(
                          styles.eventTitle,
                          day === currentDateString && styles.white
                        )}
                      >
                        {item.title.rendered}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  date: selectCurrentDate(state),
  allEvents: selectEvents(state),
  pageLoading: selectEventPageLoading(state),
  events: selectEventsForDateSorted(state),
})

const mapActionsToProps = {
  loadEvents,
  loadEventsPage,
}

export default connect(mapStateToProps, mapActionsToProps)(EventWeek)
