// @flow
import { StyleSheet } from 'aphrodite/no-important'

import { colors } from 'constants'

export default StyleSheet.create({
  banner: {
    borderRadius: 4,
    margin: '10px 0',
    marginTop: 70, // remove this
    padding: '15px 25px',
    background: colors.highlight,
    color: colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  noLink: {
    opacity: 0.7,
  },
  bannerFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  bannerTag: {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: 14,
    color: colors.white,
    lineHeight: '20px',
    padding: '2px 7px',
    background: '#AC0539',
    borderRadius: 3,
    marginRight: 15,
  },
  bannerText: {
    margin: 0,
  },
  bannerMore: {
    color: colors.white,
    fontWeight: 500,
    fontSize: 16,
    textDecoration: 'none !important',
    transition: 'all 300ms ease',
  },
})
