// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'
import styles from './style'

import type { Props } from './types'

export default function Input(props: Props): React$Node<'label'> {
  const id =
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15)

  return (
    <label htmlFor={id} className={css(styles.input)}>
      {props.icon && (
        <div className={css(styles.input__icon)}>
          <props.icon />
        </div>
      )}
      <div className={css(styles.input__wrap)}>
        <input
          onChange={props.onChange}
          type={props.type || 'text'}
          id={id}
          className={css(styles.input__entity)}
          placeholder={props.placeholder}
        />
      </div>
    </label>
  )
}
