// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { css } from 'aphrodite/no-important'
import find from 'lodash/find'

import loadTaxonomy from 'modules/taxonomy/actions'

import styles from './style'
import type { Props, State, Option } from './types'

class Dropdown extends Component<Props, State> {
  dropdown: React$Ref<'div'> = React.createRef()

  static defaultProps = {
    values: ['No values given'],
    onChange: () => {},
  }

  state = {
    open: false,
  }

  componentDidMount = () => {
    if (this.props.apiValues) {
      this.props.loadTaxonomy(this.props.apiValues)
    }
  }

  open = () => {
    this.setState(state => ({ ...state, open: true }))

    document.addEventListener('click', this.checkForClose, true)
  }

  close = () => {
    this.setState(state => ({ ...state, open: false }))

    document.removeEventListener('click', this.checkForClose, true)
  }

  checkForClose = (evt: Event) => {
    // $FlowFixMe
    if (!this.dropdown.current.contains(evt.target)) {
      this.close()
    }
  }

  changeValue = (value: Option) => {
    this.props.onChange(value)
    this.close()
  }

  render(): React$Node {
    const placeholder = this.props.placeholder || 'Alle Bereiche'
    const newapiValues = this.props.secondValues[this.props.apiValues]

    const initValue = this.props.initialValue || placeholder
    const initialValue = find(this.props.values, { value: initValue })
    const curValue = this.props.currentValue ? this.props.currentValue : initialValue

    return (
      <div
        className={css(styles.dropdown, this.state.open && styles['dropdown--active'])}
        style={this.props.style}
        ref={this.dropdown}
      >
        <div
          role="button"
          tabIndex={0}
          className={css(
            styles.dropdown__item,
            this.state.open && styles['dropdown__item--active']
          )}
          onClick={this.state.open ? this.close : this.open}
        >
          {typeof curValue === 'object' ? curValue.label : curValue}
        </div>
        <div
          className={css(
            styles.dropdown__list,
            this.state.open && styles['dropdown__list--active']
          )}
        >
          {!this.props.noFirst && (
            <div
              key={-1}
              role="button"
              tabIndex={0}
              className={css(styles.dropdown__listItem)}
              onClick={() => this.changeValue(placeholder)}
            >
              {placeholder}
            </div>
          )}
          {newapiValues &&
            newapiValues.map(
              (opt, index): React$Node => (
                <div
                  key={index.toString()}
                  role="button"
                  tabIndex={0}
                  className={css(styles.dropdown__listItem)}
                  onClick={() => this.changeValue(this.props.returnIds ? opt : opt.name)}
                >
                  {opt.name}
                </div>
              )
            )}
          {!newapiValues &&
            this.props.values &&
            this.props.values.map(
              (opt, index): React$Node => (
                <div
                  key={index.toString()}
                  role="button"
                  tabIndex={0}
                  className={css(styles.dropdown__listItem)}
                  onClick={() => this.changeValue(opt)}
                >
                  {opt.label}
                </div>
              )
            )}
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({ secondValues: state.taxonomy }),
  { loadTaxonomy }
)(Dropdown)
