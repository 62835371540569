// @flow
import React from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'

import type { Props } from './types'

const styles = StyleSheet.create({
  main: {
    maxWidth: '100%',
    overflow: 'auto',
  },
})

const Overflow = (props: Props) => (
  <div className={css(styles.main)}>
    <div style={{ minWidth: '100%', width: props.width }}>{props.children}</div>
  </div>
)

export default Overflow
