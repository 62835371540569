// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { css } from 'aphrodite/no-important'

import selectVideo from 'modules/ui/video/actions'
import { selectVideoIndex } from 'modules/ui/video/selectors'

import linkedin from 'assets/linkedin.png'

import styles from './style'
import type { Props, State } from './types'

class Article extends Component<Props, State> {
  componentDidMount() {
    if (this.props.isActive) {
      this.props.selectVideo(this.props.index)
    }
  }

  handleVideo = (index: string): React$Node => {
    if (this.props.isControl) {
      this.props.selectVideo(index)
    }
  }

  renderPreline = (): React$Node => {
    if (!this.props.isControl) {
      return (
        <div
          className={css(styles.image)}
          style={{ backgroundImage: `url(${this.props.icon || linkedin})` }}
        />
      )
    }
    if (this.props.isControl) {
      return <div className={css(styles.preline)}>OVB TV</div>
    }
    return null
  }

  render(): React$Node {
    const { image, isControl, index, activeIndex, headline, text } = this.props

    return (
      <div
        role="button"
        tabIndex="0"
        onClick={() => {
          if (typeof index !== 'undefined') {
            this.handleVideo(index)
          }
        }}
        className={css(
          styles.main,
          !!image && styles['main--preview'],
          !!isControl && styles['main--control'],
          index === activeIndex && styles['main--control-active']
        )}
      >
        {image && (
          <div className={css(styles.preview)} style={{ backgroundImage: `url(${image})` }} />
        )}

        {this.renderPreline()}

        <div className={css(styles.headline, !!isControl && styles.headlineSmall)}>{headline}</div>
        <div className={css(styles.text, !!isControl && styles.textSmall)}>{text}</div>
      </div>
    )
  }
}

export default connect(
  state => ({ activeIndex: selectVideoIndex(state) }),
  { selectVideo }
)(Article)
