// @flow
/* eslint max-len: 0, camelcase: 0 */

import fetch from 'isomorphic-fetch'
import history from 'modules/history'
import { camelizeKeys } from 'humps'
import type Response from 'isomorphic-fetch'
import Cookies from 'js-cookie'
import type { Config, Options, Response as ApiResponse } from './callApi.types'

export const CALL_API: string = 'CALL_API'

function getBaseUrl(): string {
  if (['localhost', '0.0.0.0'].includes(location.hostname)) {
    return 'https://test-de-portal.myovb.com/wp-json'
  }

  return '/wp-json'
}

export const baseUrl: string = getBaseUrl()

export default async (
  endpoint: string,
  { secure, ...options }: Options = {},
  external: ?string
): Promise<*> => {
  let url: string = endpoint.indexOf('//') !== 0 && !options.local ? baseUrl + endpoint : endpoint

  // TODO: Remove this when not using the mock endpoints anymore. This is only
  // for overwriting the URL
  if (typeof external === 'string') {
    url = external
  }

  const config: Config = Object.assign(
    {},
    {
      method: 'GET',
      credentials: 'same-origin',
    },
    options
  )

  const headers: { [key: string]: string } = {
    'Content-Type': 'application/json',
    ...config.headers,
  }

  if (secure) {
    headers.Authorization = `Bearer ${Cookies.get('token')}`
  }

  config.body = JSON.stringify(config.body)
  config.headers = headers

  const resp: Response = await fetch(url, config)
  const json: ApiResponse = await resp.json()

  if (typeof resp.ok !== 'undefined' && resp.ok) {
    const data = Object.prototype.hasOwnProperty.call(json, 'data') ? json.data : json
    return camelizeKeys(data)
  }

  if (json.data.status === 403 && url.indexOf('cipher') === -1) {
    history.push(`/login?redirectTo=${window.location.pathname}`)
  }

  const error = new Error('response failed')
  // $FlowFixMe
  error.request = resp
  error.msg = json.message

  throw error
}
