// @flow
import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import Login from './subpages/Login'
import ForgotPassword from './subpages/ForgotPassword'
import ForgotPasswordSuccess from './subpages/ForgotPasswordSuccess'

function Auth(props: Object): React$Node<Switch> {
  const { path } = props.match
  return (
    <Switch>
      <Route path={`${path}/`} exact component={Login} />
      <Route path={`${path}/passwort-vergessen`} component={ForgotPassword} />
      <Route path={`${path}/passwort-zurueckgesetzt`} component={ForgotPasswordSuccess} />
    </Switch>
  )
}

export default withRouter(Auth)
