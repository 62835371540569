// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { css } from "aphrodite/no-important";

import { selectActiveVideo } from "modules/ui/video/selectors";
import watermark from "assets/ovb_watermark.png";
import styles from "./style.js";

class Video extends Component<Props, State> {
  render(): React$Node {
    if (!this.props.video && !this.props.videoUrl) return null;

    return (
      <div className={css(styles.main)}>
        <img
          src={watermark}
          alt="OVB Watermark"
          className={css(styles.mainWatermark)}
        />
        <ReactPlayer
          url={this.props.videoUrl || this.props.video.acf.video.url}
          width="100%"
          height="100%"
          controls
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    video: selectActiveVideo(state),
  }),
  null
)(Video);
