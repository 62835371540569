// @flow
import React from 'react'

export default function LockIcon(props: Props): React$Node<'svg'> {
  return (
    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path
          d="M26 17c-4.968 0-9 4.032-9 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm0 2.7c1.494 0 2.7 1.206 2.7 2.7 0 1.494-1.206 2.7-2.7 2.7-1.494 0-2.7-1.206-2.7-2.7 0-1.494 1.206-2.7 2.7-2.7zm0 12.78c-2.25 0-4.239-1.152-5.4-2.898.027-1.791 3.6-2.772 5.4-2.772 1.791 0 5.373.981 5.4 2.772-1.161 1.746-3.15 2.898-5.4 2.898z"
          id="a"
        />
      </defs>
      <use
        fill={props.fill ? props.fill : 'currentColor'}
        xlinkHref="#a"
        transform="translate(-17 -17)"
        fillRule="evenodd"
      />
    </svg>
  )
}
