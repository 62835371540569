// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  main: {
    display: 'flex',
  },
  bar: {
    background: '#e6e9eb',
    flex: '0 0 2px',
    width: 2,
    minWidth: 2,
    height: '100%',
    position: 'relative',
  },
  nav: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '2px solid #e6e9eb',
  },
  navItem: {
    fontSize: 16,
    color: '#0073ca',
    marginTop: 8,
    marginBottom: 8,
    paddingLeft: 20,
    lineHeight: '20px',
    marginLeft: -2,
    borderLeft: '2px solid #e6e9eb',
  },
  navItemActive: {
    color: '#00509c',
    fontWeight: 500,
    borderLeft: '2px solid #00509c',
  },
})
