export const absprungTrigger = [
  "GEV24",
  "IDNOW",
  "DSE",
  "EASYZWEI",
  "EXPERTENMODELL",
  "BONIFIKATION",
];

export const absprungLink = [
  "gev24",
  "IDnow",
  "dse_fv_lsc",
  "easyZwei",
  "expertenmodell",
  "bonifikation",
];
