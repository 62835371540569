// @flow
import { stringify } from 'qs'
import { CALL_API } from 'utils/callApi'

import { LOAD_SEARCH_REQUEST, LOAD_SEARCH_SUCCESS, LOAD_SEARCH_FAILURE } from './actionTypes'

export function startSearch(value: string, postType?: string, filter?: string): Object {
  const params = { s: value }

  if (postType) params.post_type = postType
  if (filter) {
    const [orderby, order] = filter.split(',')
    if (order) params.order = order
    if (orderby) params.orderby = orderby
  }

  return {
    [CALL_API]: {
      endpoint: `/eps/v2/search?${stringify(params)}`,
      types: [LOAD_SEARCH_REQUEST, LOAD_SEARCH_SUCCESS, LOAD_SEARCH_FAILURE],
    },
  }
}
