// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  totals: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
