// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import styles from './style'

export default function Cart(): React$Element<'div'> {
  return (
    <div className={css(styles.cart)}>
      <div className={css(styles.cart__headline)}>
        <h2>
          Warenkorb <span style={{ fontSize: '.6em' }}>6 Artikel</span>
        </h2>
      </div>

      <table className={css(styles.cart__table)} cellSpacing="0">
        <thead>
          <tr className={css(styles.cart__tr)}>
            <th className={css(styles.cart__th)}>Anzahl</th>
            <th className={css(styles.cart__th)}>Artikel</th>
            <th className={css(styles.cart__th)}>Artikel-Nr.</th>
            <th className={css(styles.cart__th)}>Entfernen</th>
            <th className={css(styles.cart__th)} colSpan="2" />
            <th className={css(styles.cart__th)}>Preis</th>
          </tr>
        </thead>

        <tbody>
          {[1, 2, 3, 4, 5].map(index => (
            <tr key={index} className={css(styles.cart__tr)}>
              <td className={css(styles.cart__td)}>1</td>
              <td className={css(styles.cart__td)}>Alu-Wandrahmen mit Klemmprofilen A1</td>
              <td className={css(styles.cart__td)}>1298743</td>
              <td className={css(styles.cart__td)}>Merchandise</td>
              <td className={css(styles.cart__td)}>
                <a href="#">Entfernen</a>
              </td>
              <td className={css(styles.cart__td)}>
                <a href="#">Auf Wunschliste</a>
              </td>
              <td className={css(styles.cart__td)}>18.00€</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
