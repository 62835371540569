// @flow
import extend from 'extend-shallow'

const regexCache = {}
let all

const charSets = {
  default: {
    '&quot;': '"',
    '&#34;': '"',
    '&#034;': '"',

    '&apos;': "'",
    '&#39;': "'",
    '&#039;': "'",

    '&amp;': '&',
    '&#38;': '&',
    '&#038;': '&',

    '&gt;': '>',
    '&#62;': '>',
    '&#062;': '>',

    '&lt;': '<',
    '&#60;': '<',
    '&#060;': '<',

    '&#8211;': '–',

    '&cent;': '¢',
    '&#162;': '¢',

    '&copy;': '©',
    '&#169;': '©',

    '&euro;': '€',
    '&#8364;': '€',

    '&pound;': '£',
    '&#163;': '£',

    '&reg;': '®',
    '&#174;': '®',

    '&yen;': '¥',
    '&#165;': '¥',
  },
}

// don't merge char sets unless "all" is explicitly called
Object.defineProperty(charSets, 'all', {
  get(): * {
    return all || (all = extend({}, charSets.default, charSets.extras))
  },
})

function toRegex(chars): RegEx {
  if (regexCache.default) {
    return regexCache.default
  }
  const keys = Object.keys(chars).join('|')
  const regex = new RegExp(`(?=(${keys}))\\1`, 'g')
  regexCache.default = regex
  return regex
}

/**
 * Returns true if str is a non-empty string
 */

function isString(str: string): boolean {
  return str && typeof str === 'string'
}

/**
 * Convert HTML entities to HTML characters.
 *
 * @param  {String} `str` String with HTML entities to un-escape.
 * @return {String}
 */

function unescape(str: string): string {
  if (!isString(str)) return ''
  const chars = charSets.default
  const regex = toRegex(chars)
  return str.replace(regex, m => chars[m])
}

/**
 * Expose charSets
 */

unescape.chars = charSets.default
unescape.extras = charSets.extras
// don't trip the "charSets" getter unless it's explicitly called
Object.defineProperty(unescape, 'all', {
  get(): * {
    return charSets.all
  },
})

/**
 * Expose `unescape`
 */

export default unescape
