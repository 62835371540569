// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { colors } from 'constants'

export default StyleSheet.create({
  main: {
    position: 'fixed',
    zIndex: 10,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  closeHolder: {
    position: 'absolute',
    right: 15,
    top: 15,
    cursor: 'pointer',
  },
  close: {
    width: 18,
    height: 18,
  },
  mainBG: {
    cursor: 'pointer',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#000',
    opacity: 0.5,
  },
  mainInner: {
    position: 'relative',
    margin: '120px auto 0',
    width: 400,
    maxWidth: '90%',
    backgroundColor: '#fff',
    border: '1px solid #E8EEF2',
    boxShadow: '0 1px 2px 0 rgba(142,144,174,0.50)',
    borderRadius: 4,
    padding: '35px 25px',
  },

  subline: {
    fontSize: 12,
    color: colors.darkFont,
    letterSpacing: 0.4,
    lineHeight: '20px',
  },
  headline: {
    fontSize: 24,
    color: colors.darkFont,
    marginBottom: 10,
  },
  text: {
    fontSize: 16,
    color: colors.black,
    lineHeight: '24px',
  },
  action: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  actionItem: {
    height: 'auto',
    borderTop: '1px solid #eee',
    borderBottom: '1px solid #eee',
  },
  actionItemImage: {
    paddingTop: 10,
    paddingBottom: 10,
    maxHeight: 60,
    height: 60,
    maxWidth: '90%',
  },
})
