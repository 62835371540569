// @flow

import { CALL_API } from 'utils/callApi'

import {
  LOAD_SINGLECONTACT_REQUEST,
  LOAD_SINGLECONTACT_SUCCESS,
  LOAD_SINGLECONTACT_FAILURE,
  LOAD_CONTACTS_REQUEST,
  LOAD_CONTACTS_SUCCESS,
  LOAD_CONTACTS_FAILURE,
  SET_SORT,
} from './actionTypes'

export function loadContacts(): Object {
  return {
    [CALL_API]: {
      endpoint: '/wp/v2/personen?per_page=100',
      types: [LOAD_CONTACTS_REQUEST, LOAD_CONTACTS_SUCCESS, LOAD_CONTACTS_FAILURE],
    },
  }
}

export function loadSingleContact(id): Object {
  return {
    [CALL_API]: {
      endpoint: `/wp/v2/personen/${id}`,
      types: [LOAD_SINGLECONTACT_REQUEST, LOAD_SINGLECONTACT_SUCCESS, LOAD_SINGLECONTACT_FAILURE],
    },
  }
}

export function setSort(type): Object {
  return {
    type: SET_SORT,
    response: type,
  }
}
