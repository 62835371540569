// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { css } from 'aphrodite/no-important'

import styles from './style'

export default function Banner(props: Object): React$Node {
  return (
    <div {...props} className={css(styles.banner)}>
      <div className={css(styles.bannerFlex)}>
        <div className={css(styles.bannerTag)}>neu</div>
        <p className={css(styles.bannerText)}>
          Am 28. August findet ein Event zur IHK-Zertifizierung statt.
        </p>
      </div>
      <Link className={css(styles.bannerMore)} to="#">
        Mehr erfahren
      </Link>
    </div>
  )
}
