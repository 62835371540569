// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  container: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: 15,
    marginLeft: 10,
  },
  svg__container: {
    position: 'relative',
    width: 20,
    height: '100%',
    outline: 0,
  },
  svg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
})
