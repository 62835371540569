// @flow

import { CALL_API } from 'utils/callApi'
import { LOAD_TAX_REQUEST, LOAD_TAX_SUCCESS, LOAD_TAX_FAILURE } from './actionTypes'

export default function loadTaxonomy(slug: string): * {
  return {
    [CALL_API]: {
      endpoint: `/wp/v2/${slug}?per_page=100`,
      types: [LOAD_TAX_REQUEST, LOAD_TAX_SUCCESS, LOAD_TAX_FAILURE],
    },
  }
}
