// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { css } from 'aphrodite/no-important'

import { ZIP, PDF, XLS, PPT, DOC, MP3, MP4 } from 'components/_icons/fileTypes'
import Button from '../Button'
import Logo from '../../assets/logo@2x.png'

import type { Props } from './types'

import styles from './style'

const handleImage = (image): string => {
  if (typeof image === 'undefined' || image === 'https://google.de') {
    return 'https://picsum.photos/200/300'
  }
  return image
}

function renderFileTypeIcon(item, isList) {
  switch (item.postMimeType) {
    case 'application/pdf':
      return <PDF className={css(styles.preview__icon, isList && styles['preview__icon--list'])} />
    case 'audio/mpeg':
      return <MP3 className={css(styles.preview__icon, isList && styles['preview__icon--list'])} />
    case 'application/vnd.ms-excel':
    case 'application/vnd.ms-excel.sheet.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <XLS className={css(styles.preview__icon, isList && styles['preview__icon--list'])} />
    case 'application/zip':
      return <ZIP className={css(styles.preview__icon, isList && styles['preview__icon--list'])} />
    case 'video/mp4':
      return <MP4 className={css(styles.preview__icon, isList && styles['preview__icon--list'])} />
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return <PPT className={css(styles.preview__icon, isList && styles['preview__icon--list'])} />
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <DOC className={css(styles.preview__icon, isList && styles['preview__icon--list'])} />
    default:
      return (
        <div
          className={css(styles.preview, isList && styles['preview--listImage'])}
          style={{
            backgroundImage: `url(${Logo})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      )
  }
}

export default function ArticlePreview(props: Props): React$Node {
  const { data } = props
  const postType = data.postType || data.type

  function truncateString(str, num): any {
    if (str.length <= num) {
      return str
    }
    return `${str.slice(0, num)}...`
  }

  function formatDate(date): any {
    const newDate = new Date(date)
    let month = `${newDate.getMonth() + 1}`
    let day = `${newDate.getDate()}`
    const year = newDate.getFullYear()

    if (month.length < 2) month = `0${month}`
    if (day.length < 2) day = `0${day}`

    return `${day}.${month}.${year}`
  }

  if (data.postMimeType === 'image/jpeg' || data.postMimeType === 'image/png') {
    return null
  }

  if (postType === 'attachment') {
    return (
      <div className={css(styles.main, props.isList && styles['main--list'])}>
        <div className={css(styles.preview, props.isList && styles['preview--list'])}>
          <div className={css(styles.preview, props.isList && styles['preview--list'])}>
            {renderFileTypeIcon(data, props.isList)}
          </div>
          <div className={css(styles.content, props.isList && styles['content--list'])}>
            <div>
              <div className={css(styles.headline, props.isList && styles['headline--list'])}>
                {truncateString(data.postTitle || data.title.rendered, 80)}
              </div>
              {data.postDate && (
                <div className={css(styles.subline, props.isList && styles['subline--list'])}>
                  {formatDate(data.postDate)}
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <Button download to={data.attachmentUrl || data.guid.rendered || data.url}>
            Downloaden
          </Button>
        </div>
      </div>
    )
  }

  if (postType === 'product') {
    return (
      <a
        href={data.link || '#'}
        target="_blank"
        className={css(styles.main, props.isList && styles['main--list'])}
        rel="noreferrer"
      >
        <div className={css(styles.preview, props.isList && styles['preview--list'])}>
          <div
            className={css(styles.preview, props.isList && styles['preview--listImage'])}
            style={{
              backgroundImage: `url(${data.sourceUrl ||
                (data.betterFeaturedImage !== undefined && data.betterFeaturedImage.sourceUrl)})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <div className={css(styles.content, props.isList && styles['content--list'])}>
            <div>
              <div className={css(styles.headline, props.isList && styles['headline--list'])}>
                {data.postTitle || data.title.rendered}
              </div>
              {data.postDate && (
                <div className={css(styles.subline, props.isList && styles['subline--list'])}>
                  {formatDate(data.postDate)}
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <Button>Zum Shop</Button>
        </div>
      </a>
    )
  }

  return (
    <Link
      to={data.postType === 'page' ? data.link : `/blog/${data.postName}`}
      className={css(styles.main, props.isList && styles['main--list'])}
    >
      <div className={css(styles.preview, props.isList && styles['preview--list'])}>
        <div
          className={css(styles.preview, props.isList && styles['preview--listImage'])}
          style={{
            backgroundImage: `url(${Logo})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <div className={css(styles.content, props.isList && styles['content--list'])}>
          <div>
            <div className={css(styles.headline, props.isList && styles['headline--list'])}>
              {data.postTitle || data.title.rendered}
            </div>
            {data.postDate && (
              <div className={css(styles.subline, props.isList && styles['subline--list'])}>
                {formatDate(data.postDate)}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <Button to={data.postType === 'page' ? data.link : `/blog/${data.postName || data.slug}`}>
          {data.postType === 'page' ? 'Seite' : 'Artikel'} lesen
        </Button>
      </div>
    </Link>
  )
}
