// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { primary } from 'constants/color'

export default StyleSheet.create({
  navigation: {
    textAlign: 'left',
    fontSize: 16,
    lineHeight: 0.875,
    padding: '1.125em 0',
  },
  navigation__item: {
    color: primary,
    fontSize: '.875em',
    marginRight: '2em',
    ':last-of-type': {
      marginRight: 0,
    },
  },
})
