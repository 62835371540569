// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  main: {
    position: 'fixed',
    zIndex: 10,
    top: '50%',
    right: 0,
  },
  btn: {
    boxShadow: '0 1px 2px 0 rgba(142,144,174,0.50)',
    width: 48,
    height: 48,
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  btnFirst: {
    background: '#2771c6',
    borderRadius: '4px 4px 0 0',
  },
  btnSecond: {
    background: '#669edd',
    borderRadius: '0 0 4px 4px',
  },
  img: {
    width: 31,
    height: 17,
  },
})
