// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { rgba } from 'polished'

export default StyleSheet.create({
  loginPage__error: {
    background: rgba('red', 0.2),
    border: `1px solid ${rgba('red', 0.2)}`,
    padding: '10px 14px',
    color: 'red',
    fontSize: 14,
  },
})
