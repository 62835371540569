// @flow
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { css } from 'aphrodite/no-important'

import caret from 'assets/caret@2x.png'
import styles from './style'
import type { Props } from './types'

const PartnerBox = (props: Props): React$Node => {
  const [open, setOpen] = useState(false)
  return (
    <div className={css(styles.main)}>
      <div className={css(styles.mainLogoHolder)} onClick={() => setOpen(!open)}>
        <div className="col-md-4">
          <div className={css(styles.thirdLink)}>
            {props.logo ? (
              <img src={props.logo} alt="" height={30} className={css(styles.mainLogo)} />
            ) : (
              props.title
            )}
          </div>
        </div>
        <div className="col-md-4">
          {props.portalLink && (
            <a
              href={props.portalLink}
              target="_blank"
              rel="noopener noreferrer"
              className={css(styles.thirdLink)}
            >
              zur Seite
            </a>
          )}
        </div>
        <div className="col-md-4">
          {props.zweiterLink && (
            <a
              href={props.zweiterLink}
              target="_blank"
              rel="noopener noreferrer"
              className={css(styles.thirdLink)}
            >
              {props.zweiterLinkText}
            </a>
          )}
        </div>
        <img
          src={caret}
          style={{
            position: 'absolute',
            right: 25,
            top: 45,
            transition: 'all 300ms ease',
            transform: open && 'rotate(180deg)',
          }}
        />
      </div>
      <div className={css(styles.mainContent, open && styles.mainContentOpen)}>
        {props.children}
      </div>
    </div>
  )
}

export default PartnerBox
