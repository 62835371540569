// @flow
import conf from '../config.json'

// type Config = {
//   projectName: string,
//   cleanOnBuild: boolean,
//   server: {
//     defaultHost: string,
//     defaultPort: string,
//   },
//   progressive: {
//     enabled: boolean,
//     themeColor: string,
//   },
//   polyfillIo: {
//     enabled: boolean,
//     features: Array<string>,
//   },
//   serviceWorker: {
//     enabled: boolean,
//   },
//   defaultMetaTags: {
//     enabled: boolean,
//     openGraphEnabled: boolean,
//     title: string,
//     description: string,
//     keywords: string,
//     robots: string,
//     type: string,
//     siteName: string,
//   },
// }

export default function config(path: string): string | boolean {
  const paths: Array<string> = path.split('.')
  let current: any = conf
  let i

  for (i = 0; i < paths.length; i += 1) {
    if (current[paths[i]] === undefined) {
      return false
    }
    current = current[paths[i]]
  }

  return current
}
