// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { colors } from 'constants'
import { TABLET } from 'constants/breakpoints'

export default StyleSheet.create({
  main: {
    padding: 24,
    background: '#FFF',
    border: '1px solid #E8EEF2',
    boxShadow: '0 1px 2px 0 rgba(142,144,174,0.50)',
    borderRadius: 4,
    [TABLET]: {
      marginBottom: 24,
    },
  },
  mainTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  mainHeadline: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16,
    color: colors.darkFont,
  },
  mainMore: {
    marginTop: 0,
    fontSize: 16,
    color: '#2771C6',
    textAlign: 'right',
    cursor: 'pointer',
  },
  mainBox: {
    background: '#e9f1fb',
    borderRadius: 2,
    padding: '10px 14px',
    fontSize: 16,
    color: '#2771C6',
    lineHeight: '22px',
  },
})
