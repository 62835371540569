// @flow
import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { css } from 'aphrodite/no-important'

import { Product, Dropdown, Basket, Masthead, Pagination } from 'components'
import bereiche from 'constants/bereiche'

import { selectProducts, selectProductCategories, selectLoaded } from 'modules/products/selectors'
import {
  loadProducts,
  loadProductCategories,
  setOrderBy,
  setOrderDirection,
  setFilter,
} from 'modules/products/actions'

import Footer from 'components/Footer'
import SearchBar from 'components/Form/SearchBar'
import Sidebar from './_modules/Sidebar'
import SidebarNavigation from './_modules/SidebarNavigation'

import styles from './style'

import type { State } from './types'

class Shop extends Component<void, State> {
  state = {
    currentValue: 'Alle Bereiche',
  }

  componentDidMount = () => {
    window.location.href = '/shop'

    this.props.loadProducts()
    this.props.loadProductCategories()
  }

  handleOrder = val => {
    this.props.setOrderBy(val.by)
    this.props.setOrderDirection(val.dir)
  }

  render(): React$Node {
    return (
      <Fragment>
        <Masthead preline="OVB-Shop" headline="Unser Shop" isClean />
        <div className={css(styles.main)}>
          <Helmet title="Shop" />

          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <div className={css(styles.sidebarHolder)}>
                  <Basket />
                  {this.props.productCategories && (
                    <SidebarNavigation data={this.props.productCategories} />
                  )}
                  <Sidebar headline="Sortieren nach" onChange={val => this.handleOrder(val)} />
                  <Sidebar
                    headline="Filter"
                    type="filter"
                    onChange={val => this.props.setFilter(val.by ? val.by : [])}
                  />
                </div>
              </div>
              <div className="col-lg-9 col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-3">
                        <Dropdown
                          onChange={opt => this.setState({ currentValue: opt })}
                          currentValue={this.state.currentValue}
                          values={bereiche}
                        />
                      </div>
                      <div className="col-md-9">
                        <SearchBar />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className={css(styles.alignRight)}>
                      <Pagination />
                    </div>
                  </div>
                  {this.props.loaded &&
                    this.props.products.map(
                      (item, index): React$Node => (
                        <div
                          key={JSON.stringify(index)}
                          className="col-xl-4 col-lg-6 col-md-6 col-sm-12"
                        >
                          <Product data={item} />
                        </div>
                      )
                    )}

                  <div className="col-md-12">
                    <div className={css(styles.alignRight)}>
                      <Pagination />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Fragment>
    )
  }
}

export default connect(
  state => ({
    productCategories: selectProductCategories(state),
    products: selectProducts(state),
    loaded: selectLoaded(state),
  }),
  {
    loadProducts,
    loadProductCategories,
    setOrderBy,
    setOrderDirection,
    setFilter,
  }
)(Shop)
