// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'
import unescape from 'utils/unescape'

import type { Props } from '../types'

import styles from '../style'

const Clean = (props: Props) => {
  let path = window.location.pathname
  path = path.split('/')
  path = path.filter(str => str !== '')

  return (
    <div className={css(styles.breadcrumb)}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <span className={css(styles.preline)}>
              {props.breadcrumb &&
                props.breadcrumb
                  .slice(0)
                  .reverse()
                  .map((item, i) => {
                    let newPath = `/${path[i]}/`
                    if (i === 1) {
                      newPath = `/${path[0]}/${path[1]}/`
                    }
                    return (
                      <>
                        <a href={newPath} className={css(styles.breadcrumbLink)}>
                          {unescape(item)}
                        </a>
                        {' / '}
                      </>
                    )
                  })}
              <span className={css(styles.breadcrumbCurrent)}>{unescape(props.headline)}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Clean
