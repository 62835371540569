// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import Button from 'components/Button'
import Countdown from 'components/Countdown'
import truncate from 'utils/truncate'

import styles from './style'
import type { Props } from './types'

const btnStyle = { margin: 5 }

export default function Box({ item = {}, ...props }: Props): React$Element<'div'> {
  return (
    <div
      className={css(
        styles.box,
        !!props.isBig && styles['box--big'],
        !props.isBig && styles['box--flexColumn'],
        props.color === '#ffcc33' && styles['box--yellow']
      )}
      style={{ backgroundColor: props.color }}
    >
      {props.isBig && (
        <div className={css(styles.image)} style={{ backgroundImage: `url(${props.img})` }} />
      )}
      <div>
        <h5 className={css(styles.box__preline)}>{props.preline}</h5>
        <h3 className={css(!props.color && styles.box__headline)}>{props.headline}</h3>
        <p
          dangerouslySetInnerHTML={{ __html: props.isBig ? props.text : truncate(props.text, 80) }}
        />
      </div>

      <div>
        {props.countdown && <Countdown data={props.countdown} />}
        {props.countdown && <div style={{ marginTop: 20 }} />}

        <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: -5 }}>
          {props.dokumentStattBeitragVerlinken && (
            <Button style={btnStyle} to={props.dokument}>
              {props.buttonText}
            </Button>
          )}
          {props.globallink && (
            <Button style={btnStyle} to={props.globallink}>
              {props.globalerLinkText}
            </Button>
          )}

          {props.newlink && (
            <Button style={btnStyle} external={props.newlink.target !== ''} to={props.newlink.url}>
              {props.newlink.title}
            </Button>
          )}

          {(props.isBig && !props.showLink) ||
            (!props.dokumentStattBeitragVerlinken && item.postName && (
              <Button
                style={btnStyle}
                to={item.postType === 'post' ? `/blog/${item.postName}/` : `${item.postName}/`}
              >
                {props.buttonText}
              </Button>
            ))}
        </div>
      </div>
    </div>
  )
}
