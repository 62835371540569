// @flow
import { StyleSheet } from 'aphrodite/no-important'

import { colors } from 'constants'

export default StyleSheet.create({
  preHeading: {
    fontSize: 12,
    textTransform: 'uppercase',
    color: '#404040',
  },
  heading: {
    fontWeight: 600,
    fontSize: 20,
    marginBottom: 30,
    color: colors.darkFont,
  },
  container: {
    fontSize: 18,
    fontWeight: 400,
    backgroundColor: '#fff',
    height: '100%',
    padding: '20px 25px 100px',
  },
  container__outer: {
    boxShadow: '0 0.1em 0.5em rgba(0,0,0,0.2)',
    position: 'relative',
  },
  container__outerMargin: {
    marginBottom: 40,
  },
  img: {
    width: '100%',
    display: 'block',
  },

  footer__container: {
    position: 'absolute',
    left: 20,
    right: 20,
    bottom: 20,
  },
})
