// @flow

import { CALL_API } from 'utils/callApi'

import {
  LOAD_PARTNERS_REQUEST,
  LOAD_PARTNERS_SUCCESS,
  LOAD_PARTNERS_FAILURE,
  LOAD_BRANCHES_REQUEST,
  LOAD_BRANCHES_SUCCESS,
  LOAD_BRANCHES_FAILURE,
} from './actionTypes'

export function fetchPartners(): Object {
  return {
    [CALL_API]: {
      endpoint: `/wp/v2/partners?per_page=40`,
      types: [LOAD_PARTNERS_REQUEST, LOAD_PARTNERS_SUCCESS, LOAD_PARTNERS_FAILURE],
    },
  }
}

export function fetchBranches(): Object {
  return {
    [CALL_API]: {
      endpoint: `/wp/v2/product_branches?per_page=40`,
      types: [LOAD_BRANCHES_REQUEST, LOAD_BRANCHES_SUCCESS, LOAD_BRANCHES_FAILURE],
    },
  }
}
