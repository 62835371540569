// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { colors } from 'constants'

export default StyleSheet.create({
  banner: {
    marginTop: 20,
    backgroundColor: '#e6ecf0',
    color: colors.darkFont,
    padding: '21px 24px',
    display: 'flex',
    alignItems: 'center',
  },
  bannerCheck: {
    width: 19,
    height: 14,
    marginRight: 20,
  },
})
