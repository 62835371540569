import React, { useState } from "react";
import { connect } from "react-redux";
import { css } from "aphrodite/no-important";
import Cookies from "js-cookie";

import search from "assets/search--menu.png";
import history from "modules/history";
import checkUrl from "utils/checkUrl";
import loadMenu from "modules/menu/actions";
import { selectTopThemen } from "modules/news/selectors";
import { loadImportantNews } from "modules/news/actions";
import { selectMenuData, selectMenuLoaded } from "modules/menu/selectors";
import validateUserToken from "modules/auth/validate/actions";
import unescape from "../../utils/unescape";

import { Button } from "components";

import { Headline } from "../FCE/Typo";

import styles from "./style";

function MobileMenu(props: any): React$Node {
  const [activeMenu, setActiveMenu] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [activeThirdMenu, setActiveThirdMenu] = useState(null);
  const handleLogout = () => {
    Cookies.remove("token");
    props.validateUserToken();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    history.push("/search", { value: searchValue });
    props.toggleMenu();
  };

  const handleChange = (e) => {
    setSearchValue(e.currentTarget.value);
  };

  const showMenu = (index: number, evt) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    if (activeMenu === null) {
      setActiveMenu(index);
    } else if (activeMenu !== index) {
      setActiveMenu(index);
    } else {
      setActiveMenu(null);
    }
  };

  if (!props.menuLoaded) props.loadMenu("handheld");
  if (!props.topThemen) props.loadImportantNews();

  const entriesById = (id) => {
    let entries = []; // eslint-disable-line prefer-const

    props.menuData.items.map((innerItem): React$Node => {
      if (id.toString() === innerItem.menuItemParent) {
        entries.push({
          title: innerItem.title,
          link: innerItem.url.replace(checkUrl(), ""),
          id: innerItem.dbId,
        });
      }
      return null;
    });

    return entries;
  };

  return [
    <div className={css(styles.menu, props.active && styles.menuActive)}>
      <div className={css(styles.menuTop)}>
        <div className={css(styles.menuTopLeft)}>Menü</div>
        <div className={css(styles.menuTopRight)}>
          <span />
          <span />
          <span />
        </div>
      </div>

      <form onSubmit={handleSubmit} className={css(styles.menuSearchWrapper)}>
        <img src={search} className={css(styles.menuSearchImage)} />
        <input
          placeholder="Search"
          className={css(styles.menuSearch)}
          onChange={(e) => handleChange(e)}
        />
      </form>

      <div className={css(styles.menuContent)}>
        {props.menuLoaded &&
          props.menuData.items.map((item): React$Node => {
            if (item.menuItemParent === "0") {
              const entries = entriesById(item.dbId);

              return [
                <div
                  key={item.dbId}
                  className={css(styles.menuItem, styles.menuItemPadding)}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    if (activeMenu !== item.dbId) {
                      setActiveMenu(item.dbId);
                    } else {
                      setActiveMenu(null);
                    }
                  }}
                >
                  <div
                    key={item.dbId}
                    className={css(styles.menuItem)}
                    role="button"
                    tabIndex={0}
                  >
                    {unescape(item.title)}
                  </div>
                  <div className={css(styles.caret, activeMenu === item.dbId && styles.caretActive)}>
                    <svg width="24" height="24" viewBox="0 0 24 24"><path fill="#003366" d="M7.41,8.58 L12,13.17 L16.59,8.58 L18,10 L12,16 L6,10 L7.41,8.58Z" /></svg>
                  </div>
                </div>,
                <div
                  className={css(
                    styles.menuSubWrapper,
                    activeMenu === item.dbId && styles.menuSubWrapperActive
                  )}
                >
                  {entries &&
                    entries.map((subItem, index) => {
                      const subEntries = entriesById(subItem.id);

                      return [
                        <div
                          key={item.dbId + index}
                          className={css(
                            styles.menuItem,
                            styles.menuItemSecondPadding,
                            styles.blueBackground
                          )}
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            if (activeThirdMenu !== subItem.id) {
                              setActiveThirdMenu(subItem.id);
                            } else {
                              setActiveThirdMenu(null);
                            }
                          }}
                        >
                          <a
                            href={subItem.link}
                            key={subItem.id}
                            className={css(styles.menuItem, styles.menuItemWhite)}
                            role="button"
                            tabIndex={0}
                          >
                            {unescape(subItem.title)}
                          </a>
                          {subEntries.length > 0 && (
                            <div className={css(styles.caret, activeThirdMenu === subItem.id && styles.caretActive)}>
                              <svg width="24" height="24" viewBox="0 0 24 24"><path fill="#fff" d="M7.41,8.58 L12,13.17 L16.59,8.58 L18,10 L12,16 L6,10 L7.41,8.58Z" /></svg>
                            </div>
                          )}
                        </div>,
                        <div
                          className={css(
                            styles.menuSubWrapper,
                            activeThirdMenu === subItem.id &&
                              styles.menuSubWrapperActive
                          )}
                        >
                          {subEntries &&
                            subEntries.map((thirdItem, i) => {
                              return [
                                <div
                                  key={thirdItem.id + i}
                                  className={css(
                                    styles.menuItem,
                                    styles.menuItemThird,
                                    styles.menuItemThirdPadding
                                  )}
                                  role="button"
                                  tabIndex={0}
                                  style={{ background: "#006666" }}
                                >
                                  <a
                                    href={thirdItem.link}
                                    key={thirdItem.id}
                                    className={css(
                                      styles.menuItem,
                                      styles.menuItemLight
                                    )}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    {unescape(thirdItem.title)}
                                  </a>
                                </div>,
                              ];
                            })}
                        </div>,
                      ];
                    })}
                </div>,
              ];
            }
          })}
        <div className={css(styles.badgeHolder)}>
          {props.topThemen && (
            <Headline
              type="h6"
              style={{
                borderBottom: "1px solid",
                paddingBottom: 10,
                marginTop: 20,
              }}
            >
              Top-Themen
            </Headline>
          )}
          {props.topThemen &&
            props.topThemen.map((item) => (
              <a className={css(styles.badge)} href={item.link}>
                {item.name}
              </a>
            ))}
        </div>
      </div>

      <div className={css(styles.menuBottom)}>
        <Button
          style={{ width: "100%", textAlign: "center", marginTop: 10 }}
          onClick={() => handleLogout()}
        >
          Log out
        </Button>
      </div>
    </div>,
    <div
      className={css(
        styles.menuBackground,
        props.active && styles.menuBackgroundActive
      )}
    />,
  ];
}

export default connect(
  (state) => ({
    menuData: selectMenuData(state),
    menuLoaded: selectMenuLoaded(state),
    topThemen: selectTopThemen(state),
  }),
  { validateUserToken, loadMenu, loadImportantNews }
)(MobileMenu);
