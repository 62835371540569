// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import styles from './style'
import type { Props } from './types'

export default function PDFDownload(props: Props): React$Element<'div'> {
  return (
    <a
      target="_blank"
      href={props.link}
      className={css(styles.container, !!props.marginVertical && styles.container__marginVertical)}
      style={props.style}
      rel="noreferrer"
    >
      {!props.noIcon && (
        <svg className={css(styles.svg)} width="24" height="28" xmlns="http://www.w3.org/2000/svg">
          <g fillRule="nonzero" fill="none">
            <path
              d="M1.74 0h14.545l7.71 7.68v17.997c0 .946-.767 1.712-1.712 1.712H1.74a1.712 1.712 0 0 1-1.712-1.712V1.712C.03.766.796 0 1.741 0z"
              fill="#E2574C"
            />
            <path
              d="M23.97 7.703h-5.967a1.712 1.712 0 0 1-1.712-1.712V.017l7.679 7.686z"
              fill="#B53629"
            />
            <path
              d="M17.573 12.978c.287 0 .428-.25.428-.492 0-.25-.147-.493-.428-.493H15.94c-.319 0-.497.264-.497.556v4.014c0 .357.204.556.48.556.273 0 .478-.199.478-.556V15.46h.988c.306 0 .46-.25.46-.5 0-.244-.154-.486-.46-.486H16.4v-1.497h1.172zm-5.52-.985H10.86a.528.528 0 0 0-.555.553v4.02c0 .41.331.538.569.538h1.253c1.485 0 2.465-.976 2.465-2.483-.001-1.594-.924-2.628-2.537-2.628zm.058 4.12h-.728v-3.129h.656c.994 0 1.426.667 1.426 1.587 0 .861-.424 1.542-1.354 1.542zm-4.377-4.12H6.55c-.334 0-.521.22-.521.556v4.014c0 .357.214.556.502.556.287 0 .501-.199.501-.556V15.39h.742c.916 0 1.672-.649 1.672-1.692 0-1.021-.73-1.706-1.712-1.706zm-.02 2.456h-.682v-1.514h.682c.421 0 .69.329.69.758-.002.428-.269.756-.69.756z"
              fill="#FFF"
            />
          </g>
        </svg>
      )}
      <div className={css(styles.children__container)}>{props.children}</div>
    </a>
  )
}
