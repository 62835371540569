// @flow
import React from 'react'
import { connect } from 'react-redux'

import { selectFontSize } from 'modules/ui/font/selectors'

import type { Props } from './types'

import './style.css'

function Content(props: Props): React$Element<'div'> {
  return (
    <div
      className="content"
      style={{ fontSize: `${props.size}px` }}
      dangerouslySetInnerHTML={{ __html: props.children }} // eslint-disable-line
    />
  )
}

export default connect(
  state => ({ size: selectFontSize(state) }),
  null
)(Content)
