// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { css } from 'aphrodite/no-important'

import { colors } from 'constants'

import loadLinks from 'modules/importantLinks/actions'
import { selectLinks } from 'modules/importantLinks/selectors'

import Button from 'components/Button'
import Headline from 'components/Box/Headline'
import boxStyles from 'components/Box/style'
import styles from './style'
import type { Props } from './types'

import LinkListItem from './LinkListItem'

const searchForCipher = /(\%5B|\[)cipher(\%5D|\])/

let parseXml

if (window.DOMParser) {
  parseXml = function(xmlStr) {
    return new window.DOMParser().parseFromString(xmlStr, 'text/xml')
  }
} else if (
  typeof window.ActiveXObject !== 'undefined' &&
  new window.ActiveXObject('Microsoft.XMLDOM')
) {
  parseXml = function(xmlStr) {
    const xmlDoc = new window.ActiveXObject('Microsoft.XMLDOM')
    xmlDoc.async = 'false'
    xmlDoc.loadXML(xmlStr)
    return xmlDoc
  }
} else {
  parseXml = function() {
    return null
  }
}

class Linklist extends Component<Props, void> {
  componentDidMount = () => {
    if (!this.props.isNews) {
      this.props.loadLinks()
    } else {
      this.props.data.map((item, index) => this.handleStorage(index))
    }
  }

  handleStorage = index => {
    if (localStorage.getItem(`news-${index}`) === null) {
      localStorage.setItem(`news-${index}`, 'false')
    }
  }

  handleVisibleState = val => {
    if (val) {
      this.setState({ absprunglink: true })
    } else {
      this.setState({ absprunglink: false })
    }
  }

  render(): React$Element<'div'> {
    const { length } = this.props.links
    const split = Math.round(length / 4)

    return (
      <div>
        {this.props.isNews && <Headline isBig headline="Wichtige Nachrichten" />}
        {!this.props.isNews && <Headline isBig headline="Gut zu wissen" />}
        <div
          className={css(boxStyles.box, boxStyles['box--module'])}
          style={{ backgroundColor: '#fff' }}
        >
          {!this.props.isNews && (
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-sm-12">
                {this.props.links.map((item, index) => {
                  if (index < split)
                    return (
                      <Button
                        key={JSON.stringify(index)}
                        customclassName={styles.textlink}
                        to={item.acf.link.replace(searchForCipher, this.props.cipher)}
                      >
                        {item.title.rendered}
                      </Button>
                    )
                })}
              </div>

              <div className="col-xl-3 col-lg-6 col-sm-12">
                {this.props.links.map((item, index) => {
                  if (index >= split && index < split * 2)
                    return (
                      <Button
                        key={JSON.stringify(index)}
                        customclassName={styles.textlink}
                        to={item.acf.link.replace(searchForCipher, this.props.cipher)}
                      >
                        {item.title.rendered}
                      </Button>
                    )
                })}
              </div>

              <div className="col-xl-3 col-lg-6 col-sm-12">
                {this.props.links.map((item, index) => {
                  if (index >= split * 2 && index < split * 3)
                    return (
                      <Button
                        key={JSON.stringify(index)}
                        customclassName={styles.textlink}
                        to={item.acf.link.replace(searchForCipher, this.props.cipher)}
                      >
                        {item.title.rendered}
                      </Button>
                    )
                })}
              </div>

              <div className="col-xl-3 col-lg-6 col-sm-12">
                {this.props.links.map((item, index) => {
                  if (index >= split * 3 && index < split * 4)
                    return (
                      <Button
                        key={JSON.stringify(index)}
                        customclassName={styles.textlink}
                        to={item.acf.link.replace(searchForCipher, this.props.cipher)}
                      >
                        {item.title.rendered}
                      </Button>
                    )
                })}
              </div>
            </div>
          )}

          {this.props.isNews &&
            this.props.data.map(
              (item, index): React$Node => (
                <LinkListItem key={JSON.stringify(index)} data={item} dataIndex={index} />
              )
            )}
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({ links: selectLinks(state) }),
  { loadLinks }
)(Linklist)
