// @flow
import { StyleSheet } from 'aphrodite/no-important'

const bouncing = {
  '0%': { transform: 'translateY(0)' },
  '50%': { transform: 'translateY(-4px)' },
  '70%': { transform: 'translateY(1px)' },
  '100%': { transform: 'translateY(0)' },
}

export default StyleSheet.create({
  container: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    border: '1px solid #ededed',
    borderRadius: 4,
    ':hover svg': {
      animationName: bouncing,
      animationDuration: '400ms, 1200ms',
      animationIterationCount: '1',
    },
  },
  container__marginVertical: {
    marginTop: 10,
    marginBottom: 10,
  },
  svg: {
    marginRight: 20,
  },
  children__container: {
    fontSize: 14,
    fontWeight: 600,
  },
})
