// @flow
import React, { Component } from 'react'
import { css } from 'aphrodite/no-important'

import styles from './style'

class SidebarNavigation extends Component {
  state = { expanded: false }

  toggleExpand = () => {
    this.setState(prevState => ({ expanded: !prevState.expanded }))
  }

  render(): React$Element<'div'> {
    const { expanded } = this.state

    return (
      <div className={css(styles.nav)} onClick={this.toggleExpand}>
        <h4 className={css(styles.headline, expanded && styles.headlineActive)}>Kategorien</h4>
        <div className={css(styles.content, expanded && styles.contentActive)}>
          {this.props.data.map(
            (item, index): React$Node => (
              <div key={JSON.stringify(index)} className={css(styles.navItem)}>
                {item.name}
              </div>
            )
          )}
        </div>
      </div>
    )
  }
}

export default SidebarNavigation
