// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { colors } from 'constants'

// import './font.css'

import book1 from 'fonts/StagSans-Book-Cy-Gr-Web.eot'
import book2 from 'fonts/StagSans-Book-Cy-Gr-Web.woff'
import book3 from 'fonts/StagSans-Book-Cy-Gr-Web.woff2'

import medium1 from 'fonts/StagSans-Medium-Cy-Gr-Web.eot'
import medium2 from 'fonts/StagSans-Medium-Cy-Gr-Web.woff'
import medium3 from 'fonts/StagSans-Medium-Cy-Gr-Web.woff2'

const stagSansBook = {
  fontFamily: 'Stag Sans',
  fontStyle: 'normal',
  fontDisplay: 'fallback',
  fontWeight: 'normal',
  src: `url('${book3}') format('woff2'),
        url('${book2}') format('woff'),
        url('${book1}') format('eot')`,
}

const stagSansMedium = {
  fontFamily: 'Stag Sans',
  fontStyle: 'normal',
  fontDisplay: 'fallback',
  fontWeight: 'bold',
  src: `url('${medium3}') format('woff2'),
        url('${medium2}') format('woff'),
        url('${medium1}') format('eot')`,
}

const globalSelectorHandler = (
  selector: string[],
  _: string,
  generateSubtreeStyles: (string[]) => *
): * => {
  if (selector[0] !== '*') {
    return null
  }
  return generateSubtreeStyles(selector.slice(1))
}

const globalExtension: Object = { selectorHandler: globalSelectorHandler }
const extended = StyleSheet.extend([globalExtension])

const styles = extended.StyleSheet.create({
  globals: {
    '*html': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
      fontFamily: [stagSansBook, 'sans-serif'],
      fontFeatureSettings: '"liga", "kern"',
    },
    '**': { boxSizing: 'border-box' },
    '*ul,ol': { paddingLeft: 28 },
    '*h1, h2, h3, h4, h5, h6, strong, b': { fontFamily: [stagSansMedium, 'sans-serif'] },
    '*input,button': { fontFamily: 'inherit', fontWeight: 300 },
    '*button': {
      margin: 0,
      padding: 0,
      border: 0,
      background: 'none',
      fontSize: '100%',
      verticalAlign: 'baseline',
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      color: 'inherit',
      appearance: 'none',
      fontSmoothing: 'antialiased',
    },
    '*table': { border: 0 },
    '*table img': { maxWidth: '100%', maxHeight: 'auto', height: 'auto' },
    '*body': {
      fontSize: 16,
      fontFeatureSettings: '"liga", "kern"',
      lineHeight: 1.4,
      background: '#fff',
      color: colors.darkFont,
      margin: '0 auto',
      fontSmoothing: 'antialiased',
      fontWeight: 300,
      height: '100vh',
      boxSizing: 'border-box',
      overflowX: 'hidden',
    },
    '*a': {
      fontWeight: 'normal',
      textDecoration: 'none !important',
      transition: 'all 300ms ease',
    },
    '*p:empty': { display: 'none' },
    '*h1,h2,h3,h4,h5,h6': {
      fontWeight: 300,
    },
    '*h1': { fontFamily: [stagSansBook] },
    '*h2': {
      fontSize: 32,
      color: colors.darkFont,
      margin: '35px 0 0',
    },
    '*h3': {
      fontSize: 28,
      lineHeight: '28px',
      fontWeight: 300,
      margin: '10px 0 0',
    },
    '*h4': {
      margin: 0,
      fontSize: 24,
      color: colors.darkFont,
    },
    '*h5': {
      fontWeight: 300,
      opacity: 0.75,
      fontSize: 12,
      letterSpacing: 0.4,
      lineHeight: '20px',
      textTransform: 'uppercase',
      margin: 0,
    },
    '*h6': {
      fontWeight: 300,
      fontSize: 16,
      color: colors.darkFont,
      margin: '0 0 10px',
    },
    '*p': {
      fontSize: 16,
      lineHeight: '24px',
    },
    '*span': {
      opacity: 0.5,
      fontWeight: 300,
      // marginLeft: 10,
    },
    '*th': {
      fontWeight: 500,
    },
    '*.react-calendar': {
      width: 280,
      minWidth: 280,
      maxWidth: 280,
      marginBottom: 20,
      minHeight: 330,
    },
    '*.react-calendar__month-view__days__day': {
      height: 24,
      margin: 8,
      width: 24,
      maxWidth: 24,
      minWidth: 24,
      cursor: 'pointer',
      outline: 'none !important',
      flexBasis: '24px !important',
      flex: '0 0 24px !important',
      fontSize: 12,
      borderRadius: '100%',
      background: '#e5ebef',
      color: colors.darkFont,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '*.react-calendar__month-view__days__day--neighboringMonth': {
      background: 'none',
      opacity: 0.1,
    },
    '*.react-calendar__tile--active': {
      background: colors.dark,
      color: colors.white,
    },
    '*.react-calendar__navigation__label': {
      fontWeight: 400,
      fontSize: 18,
      color: colors.darkFont,
      textAlign: 'center',
      flex: '0 0 180px',
      marginTop: 15,
      marginBottom: 35,
      pointerEvents: 'none',
      flexGrow: '0 !important',
      paddingLeft: 12,
      paddingRight: 12,
    },
    '*.react-calendar__month-view__weekdays__weekday': {
      fontWeight: 400,
      fontSize: 10,
      color: colors.darkFont,
      textAlign: 'center',
    },
    '*.react-calendar__navigation__arrow': {
      outline: 'none',
      color: colors.darkFont,
      margin: '15px 5px 35px',
      cursor: 'pointer',
    },
    '*.react-calendar__navigation__prev2-button': {
      display: 'none',
    },
    '*.react-calendar__navigation__next2-button': {
      display: 'none',
    },
    '*.accordion__body': {
      padding: 25,
      maxHeight: 'auto',
      opacity: 1,
      transition: 'all 300ms ease',
    },
    '*.accordion__body--hidden': {
      opacity: 0,
      maxHeight: 0,
      overflow: 'hidden',
    },
    '*.accordion__title': {
      outline: 'none',
      background: '#fff',
      border: '1px solid #E8EEF2',
      boxShadow: '0 1px 2px 0 rgba(142,144,174,0.50)',
      borderRadius: '0 0 4px 4px',
      fontSize: 18,
      padding: 25,
      color: colors.darkFont,
    },
    '*.accordion__title:hover': {
      background: '#fff',
    },
    '*.accordion__title h3': {
      margin: 0,
    },

    '*.link-more': {
      display: 'none',
    },
    '*.no-gap .row': {
      marginLeft: 0,
      marginRight: 0,
    },
    '*.no-gap [class*="col-"]': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '*.gap .row': {
      marginLeft: -20,
      marginRight: -20,
    },
    '*.gap [class*="col-"]': {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
})

extended.css(styles.globals)
