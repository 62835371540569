import React, { Component } from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'

const bannerStyles = StyleSheet.create({
  wrapper: {
    marginTop: 30,
    marginBottom: 40,
    maxWidth: '100%',
    height: 44,
    background: '#CC0066',
    padding: '0 100px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  badge: {
    background: '#FFFFFF',
    borderRadius: 3,
    padding: 4,
    marginRight: 8,
    fontSize: 13,
    height: 19,
    lineHeight: 20,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: 0.4,
    color: '#CC0066',
  },
  overflow: {
    overflow: 'hidden',
  },
  inner: {
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    overflow: 'visible',
  },
  item: {
    overflow: 'visible',
    color: '#fff',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    padding: '0 50px',
  },
})

class Banner extends Component {
  constructor(props) {
    super(props)
    this.marquee = React.createRef()
  }

  componentDidMount() {
    setTimeout(() => this.marquee.current.start(), 1000)
  }

  handleHover(bool) {
    if (bool) {
      this.marquee.current.stop()
    } else {
      this.marquee.current.start()
    }
  }

  render() {
    const { data } = this.props

    if (data.acf && !data.acf.isVisible) return null

    return (
      <div className={css(bannerStyles.wrapper)}>
        {data.acf && data.acf.hasNewBadge && <div className={css(bannerStyles.badge)}>NEU</div>}
        <div className={css(bannerStyles.overflow)}>
          <marquee
            ref={this.marquee}
            className={css(bannerStyles.inner)}
            scrollamount={data.acf && data.acf.speed}
            onMouseEnter={() => this.handleHover(true)}
            onMouseLeave={() => this.handleHover(false)}
          >
            {data.acf &&
              data.acf.news.map(item => (
                <a
                  href={item.link}
                  target="_blank"
                  className={css(bannerStyles.item, !item.link && bannerStyles.noLink)}
                  rel="noreferrer"
                >
                  {item.text}
                </a>
              ))}
            {data.acf &&
              data.acf.news.map(item => (
                <a
                  href={item.link}
                  target="_blank"
                  className={css(bannerStyles.item, !item.link && bannerStyles.noLink)}
                  rel="noreferrer"
                >
                  {item.text}
                </a>
              ))}
          </marquee>
        </div>
      </div>
    )
  }
}

export default Banner
