// @flow
import { CHANGE_DATE } from './actionTypes'
import type { Action } from './types'

export default function changeDate(date: Date): Action {
  return {
    type: CHANGE_DATE,
    payload: date,
  }
}
