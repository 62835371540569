// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import styles from './style'

export default function Headline(props): React$Element<'div'> {
  return (
    <h6
      className={css(styles.headline, props.isBig && styles['headline--big'])}
      style={props.style}
    >
      {props.headline}
      <span className={css(styles.headlineInner)}>{props.children}</span>
    </h6>
  )
}
