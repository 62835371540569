// @flow
import React, { Component, Fragment } from 'react' // eslint-disable-line no-unused-vars

import Small from './small'
import Big from './big'

export default function EventsTable(props): React$Node {
  if (props.isMasthead) {
    return <Small {...props} />
  }

  return <Big {...props} />
}
