// @flow
import { StyleSheet } from 'aphrodite/no-important'

import { colors } from 'constants'
import { TABLET, MOBILE } from 'constants/breakpoints'

export default StyleSheet.create({
  menu: {
    position: 'relative',
    padding: '0 2000px',
    background: '#f6f8fa',
    transform: 'translateY(-10px)',
    opacity: 0,
    maxHeight: 0,
    overflow: 'hidden',
    pointerEvents: 'none',
    transition: 'all 300ms ease',
    margin: '0 -2000px',
    [TABLET]: {
      textAlign: 'center',
    },
  },
  menuActive: {
    padding: '0 2000px',
    transform: 'translateY(0px)',
    opacity: 1,
    maxHeight: 70,
    pointerEvents: 'all',

    [TABLET]: {
      maxHeight: '200px',
      width: 'auto',
    },
  },
  menuFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: 85,
    pointerEvents: 'none',
    height: 70,
    [TABLET]: {
      paddingLeft: 0,
      display: 'block',
      height: 'auto',
    },
  },
  menuFlexActive: {
    pointerEvents: 'all',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: colors.darkFont,
    marginRight: 0,
    paddingLeft: 15,
    paddingRight: 15,
    [TABLET]: {
      marginRight: 0,
    },
    [TABLET]: {
      display: 'block',
      lineHeight: '30px',
    },
    ':hover': {
      background: colors.primary,
      color: colors.white,
    },
  },
})
