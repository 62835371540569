import React, { Component } from "react";
import { connect } from "react-redux";
import { css } from "aphrodite/no-important";
import { Link } from "react-router-dom";

import Cookies from "js-cookie";

import checkUrl from "utils/checkUrl";
import loadMenu from "modules/menu/actions";
import { selectMenuData, selectMenuLoaded } from "modules/menu/selectors";
import validateUserToken from "modules/auth/validate/actions";
import { showSearch, toggleSearch } from "modules/ui/search/actions";
import { selectSearchState } from "modules/ui/search/selectors";
import MobileMenu from "components/MobileMenu";
import Search from "components/Search";
import styles from "./style";
import type { Props, State } from "./types";

class Header extends Component<Props, State> {
  state = {
    activeMenu: null,
    profileOpened: false,
    menu: false,
    mobileOpen: false,
  };

  componentDidMount = async (): Promise<*> => {
    this.props.loadMenu("handheld");
  };

  showMenu = (index: number, evt) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    if (this.state.activeMenu === null) {
      this.setState({ activeMenu: index });
    } else if (this.state.activeMenu !== index) {
      this.setState({ activeMenu: index });
    } else {
      this.setState({ activeMenu: null });
    }
  };

  openProfile = () => {
    this.setState({ profileOpened: true });
  };

  closeProfile = () => {
    this.setState({ profileOpened: false });
  };

  handleMenu = () => {
    this.setState((prevState) => {
      this.props.showSearch();
      return { mobileOpen: !prevState.mobileOpen };
    });
  };

  toggleSearch = () => {
    if (this.props.searchOpen) {
      this.setState({ mobileOpen: false });
    } else {
      this.setState({ mobileOpen: true });
    }

    this.props.toggleSearch();
  };

  handleLogout = () => {
    Cookies.remove("token");
    this.props.validateUserToken();
  };

  entriesById(id): Array<*> {
    let entries = []; // eslint-disable-line prefer-const

    this.props.menuData.items.map((innerItem): React$Node => {
      if (id.toString() === innerItem.menuItemParent) {
        entries.push({
          title: innerItem.title,
          link: innerItem.url.replace(checkUrl(), ""),
          id: innerItem.dbId,
        });
      }
      return null;
    });

    return entries;
  }

  render(): React$Node {
    const { mobileOpen } = this.state;
    const isMobile = window.innerWidth < 1024;

    if (!this.props.isValid) {
      return (
        <header className={css(styles.header)} style={{ padding: "10px 0" }}>
          <div className="container">
            <div className={css(styles.headerFlex)}>
              <Link
                to="/"
                className={css(styles.headerLogo, styles.headerLogoMobile)}
              />
              <div
                className={css(
                  styles.headerLeft,
                  mobileOpen && styles.headerLeftActive
                )}
              >
                <Link to="/" className={css(styles.headerLogo)} />
                <Link to="/" className={css(styles.headerLogoPortal)} />
              </div>
            </div>
          </div>
        </header>
      );
    }

    return (
      <>
      {this.state.menu && <div className={css(styles.spacer)} />}
      <header
        className={css(styles.header, this.state.menu && styles['header--fixed'])}
        onMouseLeave={() => this.showMenu(null)}
      >
        <div className="">
          <div className={css(styles.headerFlex)}>
            <Link
              to="/"
              className={css(styles.headerLogo, styles.headerLogoMobile)}
              role="button"
              tabIndex="0"
            />
            <div
              className={css(
                styles.headerLeft,
                mobileOpen && styles.headerLeftActive
              )}
            >
              <Search active={this.props.searchOpen} />
              <Link to="/" className={css(styles.headerLogo)} />
              <Link to="/" className={css(styles.headerLogoPortal)} />
            </div>
            <div
              className={css(
                styles.headerRight,
                this.state.menu && styles.headerRightActive
              )}
            >
              Menü
              <Burger
                active={this.state.menu}
                onClick={() => this.setState({ menu: !this.state.menu })}
              />
            </div>
          </div>
        </div>
        <MobileMenu
          toggleMenu={() => this.setState({ menu: !this.state.menu })}
          active={this.state.menu}
        />
      </header>
      </>
    )
  }
}

const Burger = (props) => (
  <div
    onClick={props.onClick}
    className={css(styles.burger, props.active && styles.burgerActive)}
  >
    <span
      className={css(
        styles.burgerLine,
        props.active && styles.burgerLineActiveFirst
      )}
    />
    <span
      className={css(
        styles.burgerLine,
        props.active && styles.burgerLineActiveSecond
      )}
    />
    <span
      className={css(
        styles.burgerLine,
        props.active && styles.burgerLineActiveThird
      )}
    />
  </div>
);

export default connect(
  (state) => ({
    searchOpen: selectSearchState(state),
    menuData: selectMenuData(state),
    menuLoaded: selectMenuLoaded(state),
  }),
  { showSearch, toggleSearch, validateUserToken, loadMenu }
)(Header);
