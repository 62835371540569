// @flow
import { SHOW_SEARCH, TOGGLE_SEARCH, CLOSE_SEARCH } from './actionTypes'
import type { Action } from './types'

export function showSearch(): Action {
  return {
    type: SHOW_SEARCH,
  }
}

export function toggleSearch(): Action {
  return {
    type: TOGGLE_SEARCH,
  }
}

export function closeSearch(): Action {
  return {
    type: CLOSE_SEARCH,
  }
}
