// @flow
import { createSelector } from 'reselect'
import type { Selector } from 'reselect'
import type { State as RootState } from 'modules/types'

import { selectVideos } from 'modules/videos/selectors'

import { selectUiTree } from '../selectors'
import type { State } from './types'

export const selectVideoTree: Selector<RootState, *, State> = createSelector(
  selectUiTree,
  tree => tree.video
)

export const selectVideoIndex: Selector<RootState, *, string> = createSelector(
  selectVideoTree,
  tree => tree.index
)

export const selectActiveVideo: Selector<RootState, *, string> = createSelector(
  selectVideoIndex,
  selectVideos,
  (index, videos) => videos[index]
)
