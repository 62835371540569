// @flow
import React from 'react'
import { render, hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import FontFaceObserver from 'fontfaceobserver'

import ConnectedRouter from 'pages/Router'
import { store } from 'modules/store'
import history from 'modules/history'
import config from 'app/config'

import 'utils/globalStyles'

require('es6-object-assign').polyfill()
require('es6-promise').polyfill()

if (process.env.NODE_ENV === 'production') {
  Raven.config('https://df4cac52022b462187fa2d905b0854d0@sentry.io/1275017').install()
}

if (typeof Object.assign !== 'function') {
  Object.assign = function(target) {
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object')
    }

    target = Object(target)
    for (let index = 1; index < arguments.length; index++) {
      const source = arguments[index]
      if (source != null) {
        for (const key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key]
          }
        }
      }
    }
    return target
  }
}

moment.locale('de')

console.info('Application running in', process.env.NODE_ENV, 'mode.'); // eslint-disable-line

// Install the offline plugin runtime to initialize the service workers and app
// cache for the application
if (process.env.NODE_ENV === 'production' && config('serviceWorker.enabled')) {
  require('offline-plugin/runtime').install()
}

const observer = new FontFaceObserver('Stag Sans')

observer.load().then(() => {
  if (document.body) document.body.classList.add('fonts-loaded')
})

/*
 * Render the React Pages into the actual DOM
 */

const Root: React$StatelessFunctionalComponent<any> = require('./pages/Root').default

const MOUNT_NODE: ?HTMLElement = document.getElementById('root')

if (MOUNT_NODE) {
  const mount = process.env.SERVER ? hydrate : render

  mount(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Root />
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE
  )
} else {
  throw new Error('Could not find MOUNT_NODE')
}
