// @flow
import React from 'react'

export default function XLS({ className }) {
  return (
    <svg className={className} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M458 118L347 8l-6-2H135c-31 0-56 27-56 59v220H51v148h28v14c0 32 25 59 56 59h269c31 0 57-27 57-59V125c0-3-1-5-3-7zM338 24l97 96h-53c-24 0-44-20-44-43V24zM90 327h12l14 24 13-24h12l-19 32 20 31h-13l-13-23-14 23H90l19-31-19-32zm354 120c0 23-18 41-40 41H135c-22 0-39-18-39-41v-14h187c36 0 66-33 66-74s-30-74-66-74H96V65c0-22 17-41 39-41h186v53c0 33 27 61 61 61h62v309zm-251-65v9h-40v-64h11v55h29zm42-14c-1-2-5-3-9-5-7-2-12-4-16-7s-6-7-6-12 2-9 6-13c4-3 10-5 16-5 7 0 12 2 16 6 5 4 7 8 6 14h-10c0-3-1-6-3-8l-9-3-8 2c-2 2-3 4-3 7l3 6c2 2 6 3 10 4 7 2 12 5 16 8 3 3 5 7 5 12 0 6-2 10-6 13s-10 5-17 5c-6 0-12-2-16-6-5-3-8-8-7-14h10c0 4 1 6 4 8 2 2 6 3 9 3l9-2c2-2 3-4 3-7l-3-6zm23-41h13l13 24 13-24h13l-20 32 20 31h-12l-14-23-14 23h-12l20-31-20-32z"
        fill="#013a65"
      />
    </svg>
  )
}
