// @flow
import React from 'react'

export default function MP3({ className }) {
  return (
    <svg className={className} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#013a65"
        d="M227 354c2-2 3-5 3-9s-1-7-3-9c-2-3-6-4-10-4h-16v26h16c5 0 8-1 10-4z"
      />
      <path
        fill="#013a65"
        d="M458 118L347 8l-6-2H135c-31 0-56 27-56 59v220H51v148h28v14c0 32 25 59 56 59h269c31 0 57-27 57-59V125c0-3-1-5-3-7zM338 24l97 96h-53c-24 0-44-20-44-43V24zm106 423c0 23-18 41-40 41H135c-22 0-39-18-39-41v-14h187c36 0 66-33 66-74s-30-74-66-74H96V65c0-22 17-41 39-41h186v53c0 33 27 61 61 61h62v309zM158 343l-20 52h-8l-21-53v53H97v-72h16l21 56 21-56h16v72h-13v-52zm31 52v-73h28c8 1 14 3 19 7s7 9 7 16-3 12-7 16c-5 5-11 7-19 7h-16v27h-12zm99-29c-2-2-5-3-9-3h-8v-10h8c4 0 7-1 8-3 2-2 3-5 3-8 0-4-1-6-3-8s-4-3-8-3c-3 0-6 1-8 3s-3 4-3 7h-12c0-6 2-10 6-14 5-4 10-6 17-6s13 2 17 5c4 4 6 9 6 16l-3 9c-2 3-4 5-7 6l8 7c2 3 3 6 3 11 0 6-2 12-7 15-4 4-10 6-17 6-6 0-12-2-17-5-5-4-7-9-7-15h12c0 3 1 5 3 7 3 2 5 3 9 3s7-1 9-3 3-5 3-8c0-4-1-7-3-9z"
      />
    </svg>
  )
}
