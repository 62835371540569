// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import boxStyles from 'components/Box/style'
import Headline from 'components/Box/Headline'

export default function Facebook(): React$Element<'div'> {
  return (
    <div
      className={css(
        boxStyles.box,
        boxStyles['box--white'],
        boxStyles['box--lessTopPadding'],
        boxStyles['box--lessBottomPadding'],
        boxStyles['box--fullHeight']
      )}
    >
      <Headline headline="Facebook Feed" />
      <iframe
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fovbdeutschland%2F&tabs=timeline&width=320&height=400&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId"
        width="auto"
        height="380"
        style={{ width: '100%', border: 'none', overflow: 'hidden' }}
        scrolling="no"
        frameBorder="0"
        allowFullScreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      />
    </div>
  )
}
