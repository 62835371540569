// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { primaryGlary, white } from 'constants/color'

export default StyleSheet.create({
  label: {
    backgroundColor: primaryGlary,
    color: white,
    fontSize: 14,
    lineHeight: 1,
    display: 'inline-block',
    padding: '3px 6px',
    borderRadius: 3,
  },
})
