// @flow

import {
  VALIDATE_TOKEN_REQUEST,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_FAILURE,
} from './actionTypes'
import type { State, Action } from './types'

const initialState: State = {
  isValid: false,
  loaded: false,
}

export const reducer = (state: State = initialState, { type, response }: Action): State => {
  switch (type) {
    case VALIDATE_TOKEN_REQUEST:
      return { ...state, loaded: false }

    case VALIDATE_TOKEN_SUCCESS:
      return { ...state, loaded: true, isValid: response.status === 200 }

    case VALIDATE_TOKEN_FAILURE:
      return { ...initialState, loaded: true }

    default:
      return state
  }
}
