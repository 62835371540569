// @flow
import { StyleSheet } from 'aphrodite/no-important'

import { colors } from 'constants'

export default StyleSheet.create({
  footer: {
    // marginTop: 30,
    borderTop: '1px solid #D9ECFE',
    textAlign: 'center',
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: colors.white,
    color: colors.darkFont,
  },
  text: {
    fontSize: 14,
  },
})
