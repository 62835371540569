// @flow
import React, { Component } from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'

import { Button } from 'components'
import { colors } from 'constants'

import avatar from 'assets/dummy-avatar.png'
import caret from 'assets/caret-blue.png'

import type { ContactState, Props } from './types'

const styles = StyleSheet.create({
  item: {
    marginBottom: 12,
    marginTop: 12,
    display: 'flex',
    flexDirection: 'column',
    background: colors.white,
    border: '1px solid #E8EEF2',
    boxShadow: '0 1px 2px 0 rgba(142,144,174,0.50)',
  },
  imageHolder: {
    height: 280,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden',
  },
  name: {
    position: 'absolute',
    left: 0,
    bottom: 42,
    fontSize: 16,
    color: colors.white,
    padding: '10px 30px 10px 15px',
    background: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '0 4px 4px 0',
  },
  job: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    fontSize: 16,
    color: colors.white,
    padding: '10px 30px 10px 15px',
    background: 'rgba(37, 96, 165, 0.8)',
    borderRadius: '0 4px 4px 0',
    width: '80%',
    whiteSpace: 'nowrap',
    overflow: 'auto',
  },
  infoHolder: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    paddingBottom: 15,
  },
  infoHolderAlternative: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  info: {
    fontSize: 16,
    color: colors.darkFont,
    marginBottom: 10,
  },
  infoAlternative: {
    color: colors.white,
  },
  infoInner: {
    display: 'block',
    opacity: 0.75,
    fontSize: 12,
    color: colors.darkFont,
    letterSpacing: 0.4,
    lineHeight: '20px',
    marginLeft: 0,
  },
  infoInnerAlternative: {
    color: colors.white,
  },
  more: {
    height: 70,
    fontSize: 18,
    color: colors.darkFont,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    cursor: 'pointer',
    outline: 'none',
  },
  caret: {
    width: 19,
    height: 11,
    transition: 'all 300ms ease',
  },
  caretOpen: {
    transform: 'rotate(180deg)',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    backgroundColor: '#3b6fa9',
    opacity: 0,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 25,
    paddingBottom: 25,
    color: colors.white,
    transform: 'translateY(280px)',
    transition: 'all 300ms ease',
  },
  contentHeadline: {
    fontSize: 18,
    marginTop: '10px !important',
    marginBottom: '10px !important',
    color: 'white !important',
  },
  contentText: {
    fontSize: 14,
  },
  contentActive: {
    opacity: 1,
    transform: 'translateY(0)',
  },
})

export default class Contact extends Component<Props, ContactState> {
  state = { open: false }

  toggleMore = () => {
    this.setState(state => ({ open: !state.open }))
  }

  handleSpaces = data => data && data.join(', ')

  render(): React$Element<'div'> {
    const { open } = this.state

    if (this.props.isAccordion) {
      const { data } = this.props

      return (
        <div className={css(styles.item)}>
          <div
            className={css(styles.imageHolder)}
            style={
              data.betterFeaturedImage !== null
                ? {
                    backgroundImage: `url(${data.betterFeaturedImage.sourceUrl})`,
                  }
                : null
            }
          >
            <div className={css(styles.name)}>{`${data.acf.vorname} ${data.acf.nachname}`}</div>
            <div className={css(styles.job)}>{this.handleSpaces(data.spaces)}</div>
            <div className={css(styles.content, open && styles.contentActive)}>
              <h3 className={css(styles.contentHeadline)}>
                {`${data.acf.vorname} ${data.acf.nachname}`}
              </h3>
              <div className={css(styles.infoHolder, styles.infoHolderAlternative)}>
                {data.acf.telefon && (
                  <div className={css(styles.info, styles.infoAlternative, styles.contentText)}>
                    <span className={css(styles.infoInner, styles.infoInnerAlternative)}>
                      Telefon
                    </span>
                    {data.acf.telefon}
                  </div>
                )}
                {data.acf.mail && (
                  <div className={css(styles.info, styles.infoAlternative, styles.contentText)}>
                    <span className={css(styles.infoInner, styles.infoInnerAlternative)}>
                      E-Mail
                    </span>
                    {data.acf.mail}
                  </div>
                )}
                {!!data.spaces && (
                  <div className={css(styles.info, styles.infoAlternative, styles.contentText)}>
                    <span className={css(styles.infoInner, styles.infoInnerAlternative)}>Job</span>
                    {data.acf.job}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            role="button"
            tabIndex="0"
            onClick={() => this.toggleMore()}
            className={css(styles.more)}
          >
            Kontaktinformationen
            <img src={caret} alt="" className={css(styles.caret, open && styles.caretOpen)} />
          </div>
        </div>
      )
    }

    return (
      <div className={css(styles.item)}>
        <div className={css(styles.imageHolder)} style={{ backgroundImage: `url(${avatar})` }}>
          <div className={css(styles.name)}>Max Mustermann</div>
          <div className={css(styles.job)}>Berater</div>
        </div>
        <div className={css(styles.infoHolder)}>
          <div className={css(styles.info)}>
            <span className={css(styles.infoInner)}>Telefon</span>
            0173 782 328 2332
          </div>
          <div className={css(styles.info)}>
            <span className={css(styles.infoInner)}>Telefon</span>
            0173 782 328 2332
          </div>
          <div className={css(styles.info)}>
            <span className={css(styles.infoInner)}>Telefon</span>
            0173 782 328 2332
          </div>
          <Button to="#">Kontaktieren</Button>
        </div>
      </div>
    )
  }
}
