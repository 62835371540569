// @flow
import { createSelector } from 'reselect'
import type { Selector } from 'reselect'

import { selectCurrentDate } from '../date/selectors'
import { selectCalendarTree } from '../selectors'
import type { State, Events } from './types'

export const selectEventsTree: Selector<*, *, State> = createSelector(
  selectCalendarTree,
  tree => tree.events
)
export const selectLoaded: Selector<*, *, boolean> = createSelector(
  selectEventsTree,
  tree => tree.loaded
)
export const selectEvents: Selector<*, *, Events> = createSelector(
  selectEventsTree,
  tree => tree.data
)

export const selectEventPageLoading: Selector<*, *, Events> = createSelector(
  selectEventsTree,
  tree => tree.pageLoading
)

export const selectEventsForDate: Selector<*, *, Events> = createSelector(
  selectEvents,
  selectCurrentDate,
  (events, date) => events.filter(event => moment(event.acf.datum).isSame(moment(date), 'day'))
)

export const selectEventsForDateSorted: Selector<*, *, Events> = createSelector(
  selectEventsForDate,
  events => events.sort((a, b) => a.acf.start - b.acf.start)
)
