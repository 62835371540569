// @flow
import { createSelector } from 'reselect'
import type { Selector } from 'reselect'
import type { State as RootState } from '../types'
import type { State, Products } from './types'

export const selectLinksTree = (state: RootState): State => state.links

export const selectLoaded: Selector<*, *, boolean> = createSelector(
  selectLinksTree,
  tree => tree.loaded
)

export const selectLinks: Selector<*, *, Products> = createSelector(
  selectLinksTree,
  tree => tree.data
)
