// @flow
import { createSelector } from 'reselect'
import { selectCalendarTree } from '../selectors'
import type { State as RootState } from '../types'
import type { State } from './types'

export const selectCurrentDate = createSelector(
  selectCalendarTree,
  (calendar: RootState): State => calendar.date
)
