// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'
import Video from 'components/Video'
import styles from './style'

import type { Props } from './types'

export default function Excerpt(props: Props): React$Element<'div'> {
  const { children, author, date, title, Footer, margin, media } = props
  return (
    <div className={css(styles.container__outer, margin && styles.container__outerMargin)}>
      <div className={css(styles.img__container)}>
        <Video videoUrl={media.video} />
      </div>
      <div className={css(styles.container)}>
        <div className={css(styles.preHeading)}>
          {author} vom {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}
        </div>
        <div className={css(styles.heading)}>{title}</div>
        {children}
        <div className={css(styles.footer__container)}>{Footer}</div>
      </div>
    </div>
  )
}
