// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { colors } from 'constants'

export default StyleSheet.create({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '13px 0',
    boxShadow: `inset 0 -1px 0 0 ${colors.darkFont}`,
    cursor: 'pointer',
  },
  mainRight: { flex: '1 1 150px' },
  headline: {
    fontWeight: 400,
    fontSize: 14,
    color: colors.darkFont,
    marginBottom: 4,
  },
  subline: {
    fontWeight: 400,
    fontSize: 12,
    color: colors.darkFont,
  },
  date: {
    fontWeight: 400,
    fontSize: 14,
    color: '#7f9cb2',
    textAlign: 'right',
  },
  time: {
    fontWeight: 400,
    fontSize: 14,
    color: '#7f9cb2',
    textAlign: 'right',
  },
})
