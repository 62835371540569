// @flow

import { TOGGLE_MODAL } from './actionTypes'
import type { State, Action } from './types'

const initialState: State = {
  open: false,
}

export const reducer = (state: State = initialState, { type }: Action): State => {
  switch (type) {
    case TOGGLE_MODAL:
      return { open: !state.open }

    default:
      return state
  }
}
