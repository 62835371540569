// @flow

import { orderBy } from 'lodash'

import {
  LOAD_PRODUCTS_REQUEST,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_FAILURE,
  LOAD_PRODUCTCATEGORIES_REQUEST,
  LOAD_PRODUCTCATEGORIES_SUCCESS,
  LOAD_PRODUCTCATEGORIES_FAILURE,
  ORDER_BY,
  ORDER_DIRECTION,
  SET_FILTER,
} from './actionTypes'
import type { State, Action } from './types'

const initialState: State = {
  loaded: false,
  orderByVal: 'name',
  orderDirection: 'asc',
  filters: [],
  data: [],
  categories: [],
}

export const reducer = (
  state: State = initialState,
  { type, response, payload }: Action
): State => {
  switch (type) {
    case LOAD_PRODUCTS_REQUEST:
      return { ...state, loaded: false }

    case LOAD_PRODUCTS_SUCCESS:
      return { ...state, loaded: true, data: response }

    case LOAD_PRODUCTS_FAILURE:
      return initialState

    case LOAD_PRODUCTCATEGORIES_REQUEST:
      return { ...state, loaded: false }

    case LOAD_PRODUCTCATEGORIES_SUCCESS:
      return { ...state, loaded: true, categories: response }

    case LOAD_PRODUCTCATEGORIES_FAILURE:
      return initialState

    case ORDER_BY:
      return { ...state, orderByVal: payload }
    case ORDER_DIRECTION:
      return { ...state, orderDirection: payload }
    case SET_FILTER:
      return { ...state, filters: payload }

    default:
      return state
  }
}
