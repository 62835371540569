// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  main: {
    paddingTop: 50,
  },
  mainHeadline: {
    marginTop: 0,
  },
  emptyState: {
    textAlign: 'center',
    padding: '40px 0',
  },
  ansprechpartner: {
    padding: '20px 0',
    ':first-child': { paddingTop: 0 },
    ':last-child': { paddingBottom: 0 },
    ':not(:last-child)': {
      borderBottom: '1px solid #eee',
    },
  },
  bar: {
    marginTop: 40,
    marginBottom: 45,
  },
  mainHolder: {
    padding: '100px 0 55px',
  },
  textlink: {
    fontSize: 16,
    color: '#2771C6',
    lineHeight: '34px',
    display: 'block',
  },
  videoHolder: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  headline: {
    marginBottom: 30,
  },
})
