// @flow

import Cookies from 'js-cookie'
import { FETCH_TOKEN_REQUEST, FETCH_TOKEN_SUCCESS, FETCH_TOKEN_FAILURE } from './actionTypes'
import type { State, Action } from './types'

const initialState: State = {
  error: [],
  loaded: false,
  data: [],
}

export const reducer = (state: State = initialState, { type, response, error }: Action): State => {
  switch (type) {
    case FETCH_TOKEN_REQUEST:
      return { ...state, loaded: false, error: [] }

    case FETCH_TOKEN_SUCCESS:
      Cookies.set('token', response.token)
      return { ...state, loaded: true, data: response, error: [] }

    case FETCH_TOKEN_FAILURE:
      return { initialState, error }

    default:
      return state
  }
}
