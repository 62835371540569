import { createSelector } from "reselect";
import type { State as RootState } from "../types";
import type { State } from "./types";

export const selectNewsTree = (state: RootState): State => state.news;

export const selectLoaded = createSelector(
  selectNewsTree,
  (tree) => tree.loaded
);
export const selectArticle = createSelector(
  selectNewsTree,
  (tree) => tree.article
);
export const selectArticleLoaded = createSelector(
  selectNewsTree,
  (tree) => tree.articleLoaded
);
export const selectNewsData = createSelector(
  selectNewsTree,
  (tree) => tree.data
);
export const selectLatestPost = createSelector(
  selectNewsTree,
  (tree) => tree.data
);
export const selectNewsbanner = createSelector(
  selectNewsTree,
  (tree) => tree.newsbanner
);
export const selectArticleOfTheWeek = createSelector(
  selectNewsData,
  (newsData) => newsData.filter((article) => article.acf.nachrichtDerWoche)
);

export const selectImportantTree = createSelector(
  selectNewsTree,
  (news) => news.important
);

export const selectTopThemen = createSelector(
  selectImportantTree,
  (tree) => tree.topThemen
);

export const selectImportantArticle = createSelector(
  selectNewsData,
  (newsData) => newsData.filter((article) => article.acf.istWichtig)
);

export const selectHighligtedArticle = createSelector(
  selectNewsData,
  (newsData) => newsData.filter((article) => article.acf.highlight)
);
