// @flow
import { INCREASE_FONT, DECREASE_FONT } from './actionTypes'
import type { Action } from './types'

export function increaseFontSize(): Action {
  return {
    type: INCREASE_FONT,
  }
}

export function decreaseFontSize(): Action {
  return {
    type: DECREASE_FONT,
  }
}
