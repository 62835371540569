// @flow
import React from 'react'

export default function PPT({ className }) {
  return (
    <svg className={className} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <g fill="#013a65">
        <path d="M142.6 354.2c2.3-2.5 3.5-5.6 3.5-9.4s-1.2-7-3.5-9.5-5.8-3.8-10.5-3.8H116V358h16.2c4.6-.1 8.1-1.3 10.4-3.8z" />
        <path d="M458 118.2L346.5 8.4a8.3 8.3 0 0 0-5.8-2.4H135c-31.1 0-56.3 26.6-56.3 59.4v219.2H51.3v148.6h27.4v13.4c0 32.7 25.3 59.4 56.3 59.4h269.4c31.1 0 56.3-26.6 56.3-59.4V124.7a9 9 0 0 0-2.7-6.5zM337.9 24.4l97 95.6h-52.8a43.9 43.9 0 0 1-44.2-43.4zm105.8 422.2a40.5 40.5 0 0 1-39.3 41.5H135a40.5 40.5 0 0 1-39.3-41.5v-13.4h187c36.5 0 66-33.3 66-74.3s-29.6-74.3-66-74.3h-187V65.4A40.5 40.5 0 0 1 135 23.9h185.9v52.7a61.3 61.3 0 0 0 61.2 61.3h61.6zm-340.3-50.2v-75h28.7c8.3 0 14.8 2.1 19.4 6.4s7 9.9 7 16.9-2.3 12.7-7 16.9-11.2 6.4-19.4 6.4H116v28.4zm70.4 0v-75h28.7c8.3 0 14.8 2.1 19.4 6.4s7 9.9 7 16.9-2.3 12.7-7 16.9-11.2 6.4-19.4 6.4h-16.2v28.4zm64.2-64.9v-10h58.5v10h-23.2v65h-12.5v-65z" />
        <path d="M213 354.2c2.3-2.5 3.5-5.6 3.5-9.4s-1.2-7-3.5-9.5-5.8-3.8-10.5-3.8h-16.2V358h16.2c4.7-.1 8.1-1.3 10.5-3.8z" />
      </g>
    </svg>
  )
}
