import React from "react"

import { Headline } from "./Typo"

export class Formular extends React.Component {
    render() {
        return (
            <div className="container">
                <Headline type="h2">{this.props.data.headline}</Headline>
                <div className="row">
                    {this.props.data.felder.map(item => (
                        <div className={`col-sm-${item.breite} col-xs-12`}>{item.name}</div>
                    ))}
                </div>
            </div>
        )
    }
}
