import { StyleSheet } from "aphrodite/no-important";
import { colors } from "constants";
import { grey0 } from "constants/color";
import { TABLET, DESKTOP, MOBILE } from "constants/breakpoints";

import logo from "assets/logo@2x.png";
import logoPortal from "assets/logo-portal.png";
import search from "assets/search@2x.png";

const burgerLineActiveStyle = {
  background: "#fff",
};

export default StyleSheet.create({
  header: {
    borderBottom: "2px solid #E8EEF2",
    background: colors.white,
    position: "relative",
    padding: "10px 25px",
  },
  "header--fixed": {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 99,
  },
  spacer: {
    height: 88,
  },
  headerFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerLeft: {
    flex: 1,
    position: "relative",
    zIndex: 1,
    alignItems: "center",
    display: "flex",
    [TABLET]: {
      transform: "translateY(0)",
      pointerEvents: "none",
      transition: "all 300ms ease",
      position: "absolute",
      opacity: 0,
      background: "#fff",
      padding: 25,
      flexDirection: "column",
      width: "100%",
      top: "calc(100% + 2px)",
      left: 0,
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    },
  },
  headerLeftActive: {
    [TABLET]: {
      pointerEvents: "initial",
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  headerRight: {
    position: "absolute",
    right: 0,
    top: 0,
    height: "100%",
    width: 210,
    display: "flex",
    zIndex: 200,
    alignItems: "center",
    backgroundColor: "rgb(0, 51, 102)",
    display: "flex",
    color: "#fff",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 300ms ease",
  },
  headerRightActive: {
    color: "rgb(0, 51, 102)",
  },
  headerLogo: {
    outline: "none",
    display: "flex",
    alignItems: "center",
    margin: "0 15px",
    marginLeft: 0,
    width: 40,
    height: 47,
    backgroundImage: `url(${logo})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    [TABLET]: {
      display: "none",
    },
  },
  headerLogoPortal: {
    outline: "none",
    display: "flex",
    alignItems: "center",
    margin: "0 15px",
    marginLeft: 0,
    width: 68,
    height: 68,
    backgroundImage: `url(${logoPortal})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    [TABLET]: {
      display: "none",
    },
  },
  headerLogoMobile: {
    display: "none",
    [TABLET]: {
      display: "block",
      paddingTop: 35,
      paddingBottom: 35,
    },
  },
  headerSearch: {
    display: "flex",
    alignItems: "center",
    padding: "10px 15px",
    borderRadius: 4,
    height: "42px",
    transition: "all 300ms ease",
    cursor: "pointer",
    outline: "none",
    ":hover": {
      background: "#e9eef2",
    },
    [TABLET]: {
      display: "none",
    },
  },
  headerSearchActive: {
    background: "#e9eef2",
    borderRadius: "0 4px 4px 0",
  },
  headerSearchInner: {
    width: 23,
    height: 23,
    backgroundImage: `url(${search})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  headerAvatar: {
    backgroundImage: `url('https://picsum.photos/50')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: 30,
    height: 30,
    borderRadius: "100%",
    marginRight: 10,
    [MOBILE]: {
      display: "none",
    },
  },
  headerLink: {
    cursor: "pointer",
    outline: "none",
    transition: "all 300ms ease",
    fontWeight: 300,
    fontSize: 14,
    color: colors.darkFont,
    padding: "35px 15px",
    display: "flex",
    alignItems: "center",
    ":hover": {
      background: colors.primary,
      color: colors.white,
    },
    [DESKTOP]: {
      fontSize: 11,
    },
    [TABLET]: {
      padding: "10px 15px",
      position: "relative",
      fontSize: 12,
    },
  },
  headerLinkActive: {
    background: colors.primary,
    color: colors.white,
  },
  headerLinkSmall: {
    padding: "30px 15px",
    position: "relative",
  },
  headerLinkMobile: {
    display: "none",
    [TABLET]: {
      display: "flex",
    },
  },
  caret: {
    marginLeft: 10,
    width: 8,
    height: 5,
    [MOBILE]: {
      margin: 0,
    },
  },
  "caret--nav": {
    marginLeft: 10,
    width: 8,
    height: 5,
  },

  headerProfileNav: {
    position: "absolute",
    top: "100%",
    width: "200px",

    backgroundColor: "white",
    padding: 0,
    margin: 0,
    listStyle: "none",
    right: 0,
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.20), inset 0 2px 0 rgba(0,0,0,0.10)",
    borderRadius: "0 0 4px 4px",

    transition: "all 200ms ease-in-out",
    pointerEvents: "none",
    opacity: 0,
    transformOrigin: "50% 0",
    transform: "perspective(50em) scaleY(0.8)",
  },

  "headerProfileNav--active": {
    pointerEvents: "initial",
    opacity: 1,
    transformOrigin: "50% 0",
    transform: "perspective(50em) scaleY(1)",
  },

  headerProfileNavItem: {
    fontSize: 14,
    outline: "none",
    lineHeight: "1em",
    padding: "1em 24px",
    userSelect: "none",
    cursor: "pointer",
    transition: "all 200ms linear",
    ":hover": {
      backgroundColor: grey0,
    },
    ":active": {
      backgroundColor: grey0,
    },
  },

  burger: {
    marginLeft: 20,
    cursor: "pointer",
    position: "relative",
    zIndex: 200,
  },

  burgerLine: {
    display: "block",
    width: 35,
    height: 4,
    background: "#fff",
    margin: "5px 0px",
    opacity: 1,
    transition: "all 300ms ease",
  },

  burgerLineActiveFirst: {
    ...burgerLineActiveStyle,
    transform: "rotate(45deg) translate(5px, 5px)",
  },
  burgerLineActiveSecond: {
    ...burgerLineActiveStyle,
    display: "none",
  },
  burgerLineActiveThird: {
    ...burgerLineActiveStyle,
    transform: "rotate(-45deg) translate(2px, -1px)",
  },
});
