import React from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'

const styles = StyleSheet.create({
  wrapper: {
    padding: 5,
    position: 'absolute',
    whiteSpace: 'nowrap',
    background: '#003A65',
    color: '#fff',
    bottom: 'calc(100% + 5px)',
    left: '-5px',
    borderRadius: 2,
    opacity: 0,
  },
  'wrapper--active': {
    opacity: 1,
  },
  caret: {
    background: '#003A65',
    width: 10,
    height: 10,
    transform: 'rotate(45deg)',
    position: 'absolute',
    top: 'calc(100% - 5px)',
    left: 8,
  },
})

const Tooltip = ({ title, active }) => (
  <div className={css(styles.wrapper, active && styles['wrapper--active'])}>
    {title}
    <div className={css(styles.caret)} />
  </div>
)

export default Tooltip
