// @flow

import { SHOW_SEARCH, TOGGLE_SEARCH, CLOSE_SEARCH } from './actionTypes'
import type { State, Action } from './types'

const initialState: State = {
  open: false,
}

export const reducer = (state: State = initialState, { type }: Action): State => {
  switch (type) {
    case SHOW_SEARCH:
      return { open: true }

    case TOGGLE_SEARCH:
      return { open: !state.open }

    case CLOSE_SEARCH:
      return { open: false }

    default:
      return state
  }
}
