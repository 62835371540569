// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { css } from 'aphrodite/no-important'

import PDFDownload from 'components/PDFDownload'

import 'react-accessible-accordion/dist/fancy-example.css'

import { Footer, Masthead } from 'components'

import { loadAnsprechpartnerOptions } from 'modules/news/actions'
import { loadContacts, setSort } from 'modules/contact/actions'
import { selectContacts, selectLoaded } from 'modules/contact/selectors'
import SideBarNavigation from 'components/Sidebar/Navigation'
import SideBarContact from 'components/Sidebar/Contact'
import SearchBar from 'components/Form/SearchBar'
import Spin from 'components/Spin'

import styles from './style'

class Ansprechpartner extends Component {
  state = { filter: false, category: false }

  componentDidMount = () => {
    this.props.loadContacts()
    this.props.loadAnsprechpartnerOptions()
  }

  handleChange = val => {
    if (val.id) {
      this.props.setSort(val.id)
      this.setState({ currentValue: val.name })
    } else {
      this.props.setSort(val)
      this.setState({ currentValue: val })
    }
  }

  onChange = e => {
    this.setState({ filter: e })
  }

  onFilterChange = e => {
    if (e === 'Alle Bereiche') {
      this.setState({ category: false })
    } else {
      this.setState({ category: e })
    }
  }

  render(): React$Element<'div'> {
    let { contacts } = this.props

    if (this.state.filter) {
      contacts = contacts.filter(
        contact =>
          (contact.acf.vorname &&
            contact.acf.vorname.toLowerCase().indexOf(this.state.filter.toLowerCase())) !== -1 ||
          (contact.acf.nachname &&
            contact.acf.nachname.toLowerCase().indexOf(this.state.filter.toLowerCase())) !== -1 ||
          (contact.title.rendered &&
            contact.title.rendered.toLowerCase().indexOf(this.state.filter.toLowerCase())) !== -1
      )
    }

    if (this.state.category && contacts) {
      contacts = contacts.filter(
        contact => contact.spaces && contact.spaces.includes(this.state.category)
      )
    }

    if (contacts) {
      contacts = contacts.sort((a, b) => {
        const nameA = a.acf.nachname && a.acf.nachname.toLowerCase()
        const nameB = b.acf.nachname && b.acf.nachname.toLowerCase()

        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        return 0
      })
    }

    return (
      <div>
        <Helmet title="Home" />
        <Masthead preline="Ihre OVB" headline="Ansprechpartner" />
        <div className={css(styles.main)}>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <h4 className={css(styles.headline)}>Seitenbereiche</h4>
                <SideBarNavigation />
              </div>
              <div className="col-lg-9 col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className={css(styles.mainHeadline)}>
                      Den richtigen Ansprechpartner finden
                    </h2>
                    <p>
                      Schnell und einfach den richtigen Ansprechpartner finden. Wählen Sie im Menü
                      nach Suchbegriff/Abteilung oder suchen Sie OVB Mitarbeiter über den Namen.
                    </p>
                    <p>Hier finden Sie die Übersicht als PDF zum Download:</p>
                    {this.props.erstePDF && (
                      <PDFDownload
                        link={this.props.erstePDF.url}
                        style={{ marginTop: 25, marginBottom: 25 }}
                      >
                        {this.props.erstePDF.title}
                      </PDFDownload>
                    )}
                    {this.props.zweitePDF && (
                      <PDFDownload
                        link={this.props.zweitePDF.url}
                        style={{ marginTop: 25, marginBottom: 25 }}
                      >
                        {this.props.zweitePDF.title}
                      </PDFDownload>
                    )}
                  </div>
                </div>

                <SearchBar
                  dropDownSize={4}
                  placeholder="Suchen Sie nach Namen"
                  dropDown={{ apiValues: 'abteilung' }}
                  onChange={this.onChange}
                  onFilterChange={this.onFilterChange}
                />

                {contacts.length ? (
                  <div className="row">
                    {this.props.loaded && (
                      <div className="col-md-12">
                        <br />
                        <h4>Ansprechpartner</h4>
                        <br />
                      </div>
                    )}

                    {this.props.loaded ? (
                      contacts.map(item => (
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <SideBarContact data={item} isAccordion />
                        </div>
                      ))
                    ) : (
                      <div className="col-12" style={{ textAlign: 'center', padding: '40px 0' }}>
                        <Spin x2 />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={css(styles.emptyState)}>
                    <img src={require('assets/empty-state.png')} alt="" style={{ maxWidth: 344 }} />

                    <h3>Leider haben wir nichts zu Ihrer Suche gefunden.</h3>
                    <p>Versuchen Sie es doch bitte mit einem anderen Suchbegriff.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default connect(
  state => ({
    loaded: selectLoaded(state),
    contacts: selectContacts(state),
    erstePDF: state.news.erstePDF,
    zweitePDF: state.news.zweitePDF,
  }),
  { loadContacts, setSort, loadAnsprechpartnerOptions }
)(Ansprechpartner)
