// @flow
import React from 'react'

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion'

import type { Props } from './types'

export default function Expand(props: Props): React$Node {
  return (
    <Accordion>
      <AccordionItem>
        <AccordionItemTitle>
          <h3>{props.title}</h3>
        </AccordionItemTitle>
        <AccordionItemBody>{props.children}</AccordionItemBody>
      </AccordionItem>
    </Accordion>
  )
}
