// @flow
import React from 'react'

export default function MP4({ className }) {
  return (
    <svg className={className} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#013a65"
        d="M227 354c2-2 3-5 3-9s-1-7-3-10c-2-2-6-3-11-3h-16v26h16c5 0 9-1 11-4zM284 342l-18 28h19v-31z"
      />
      <path
        fill="#013a65"
        d="M458 118L347 8l-6-2H135c-31 0-56 27-56 59v220H51v148h28v14c0 32 25 59 56 59h269c31 0 57-27 57-59V125c0-3-1-5-3-7zM338 24l97 96h-53c-24 0-44-20-44-43V24zM93 321h16l22 58 22-58h16v75h-13v-54l-21 54h-8l-21-55h-1v55H93v-75zm351 126c0 23-18 41-40 41H135c-22 0-39-18-39-41v-14h187c36 0 66-33 66-74s-30-74-66-74H96V65c0-22 17-41 39-41h186v53c0 33 27 61 61 61h62v309zm-256-51v-75h28c9 0 15 2 20 7s7 10 7 17-3 12-7 17c-5 4-11 6-20 6h-16v28h-12zm119-26v10h-9v16h-13v-16h-31l-1-8 32-51h12v49h10z"
      />
    </svg>
  )
}
