// @flow

import {
  LOAD_VIDEOS_REQUEST,
  LOAD_VIDEOS_SUCCESS,
  LOAD_VIDEOS_FAILURE,
  SET_SORT,
} from './actionTypes'
import type { State, Action } from './types'

const initialState: State = {
  loaded: false,
  data: [],
  sort: '',
}

export const reducer = (state: State = initialState, { type, response }: Action): State => {
  switch (type) {
    case LOAD_VIDEOS_REQUEST:
      return { ...state, loaded: false }

    case LOAD_VIDEOS_SUCCESS:
      return { ...state, loaded: true, data: response }

    case LOAD_VIDEOS_FAILURE:
      return initialState

    case SET_SORT:
      return { ...state, sort: response }

    default:
      return state
  }
}
