// @flow
import { StyleSheet } from 'aphrodite/no-important'

const zooming = {
  '0%': { transform: 'scale(1)' },
  '25%': { transform: 'scale(0.8)' },
  '35%': { transform: 'scale(1.1)' },
  '50%': { transform: 'scale(1)' },
}

export default StyleSheet.create({
  main: {
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
  },
  mainWatermark: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '10%',
  },
  overlay: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 300ms ease',
  },
  icon: {
    cursor: 'pointer',
    outline: 'none',
    width: 72,
    height: 72,
    ':hover': {
      animationName: zooming,
      animationDuration: '800ms, 1200ms',
      animationIterationCount: '1',
    },
  },
  video: {
    width: '100%',
    flex: 1,
    display: 'block',
    backgroundColor: 'white',
  },
  'overlay--disabled': {
    opacity: 0,
    pointerEvents: 'none',
    transform: 'scale(0.1)',
  },
})
