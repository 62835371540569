// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import styles from './style'

import type { Props } from './types'

export default function Item(props: Props): React$Node {
  return (
    <div className={css(styles.flex, props.left && styles.flex__left)}>
      <div className={css(styles.type, props.left && styles.type__left)}>{props.name}</div>
      <div className={css(styles.info, props.left && styles.info__left, props.bold && styles.bold)}>
        {props.children}
      </div>
    </div>
  )
}
