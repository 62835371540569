// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { colors } from 'constants'

export default StyleSheet.create({
  headline: {
    display: 'flex',
    alignItems: 'center',
    color: colors.primary,
    justifyContent: 'space-between',
    fontSize: 24,
  },
  'headline--big': {
    fontWeight: 600,
  },
  headlineInner: {
    opacity: 0.5,
    fontSize: 16,
  },
})
