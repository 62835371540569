// @flow
import { createSelector } from 'reselect'
import type { Selector } from 'reselect'
import type { State as RootState } from 'modules/types'

import { selectUiTree } from '../selectors'
import type { State } from './types'

export const selectModalTree: Selector<RootState, *, State> = createSelector(
  selectUiTree,
  tree => tree.modal
)

export const selectModalOpen: Selector<RootState, *, boolean> = createSelector(
  selectModalTree,
  tree => tree.open
)
