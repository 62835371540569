// @flow
import { createSelector } from 'reselect'
import type { State as RootState } from '../types'
import type { State } from './types'

export const selectContactsTree = (state: RootState): State => state.contacts

export const selectLoaded = createSelector(selectContactsTree, tree => tree.loaded)

export const selectContacts = createSelector(
  selectContactsTree,
  ({ data, sortBy }): React$Node => {
    if (sortBy !== 'Alle Bereiche') {
      const newData = data.filter(item => item.abteilung[0] === sortBy)
      return newData
    }

    return data
  }
)

export const selectSingleContact = createSelector(selectContactsTree, tree => tree.singleData)
