// @flow
export const LOAD_PRODUCTS_REQUEST: string = 'LOAD_PRODUCTS_REQUEST'
export const LOAD_PRODUCTS_SUCCESS: string = 'LOAD_PRODUCTS_SUCCESS'
export const LOAD_PRODUCTS_FAILURE: string = 'LOAD_PRODUCTS_FAILURE'

export const LOAD_PRODUCTCATEGORIES_REQUEST: string = 'LOAD_PRODUCTCATEGORIES_REQUEST'
export const LOAD_PRODUCTCATEGORIES_SUCCESS: string = 'LOAD_PRODUCTCATEGORIES_SUCCESS'
export const LOAD_PRODUCTCATEGORIES_FAILURE: string = 'LOAD_PRODUCTCATEGORIES_FAILURE'

export const ORDER_BY: string = 'ORDER_BY'
export const ORDER_DIRECTION: string = 'ORDER_DIRECTION'
export const SET_FILTER: string = 'SET_FILTER'
