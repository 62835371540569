import React from "react";
import Slider from "react-slick";
import Helmet from "react-helmet";
import { css, StyleSheet } from "aphrodite/no-important";
import { MOBILE } from 'constants/breakpoints'

import { Headline, Text } from "./Typo";

import caret from "assets/arrow-ovb.svg";

class Person extends React.Component {
  render() {
    const { bild, name, funktion } = this.props.data;
    const { onClick } = this.props;
    return (
      <div className={css(styles.person)} onClick={onClick}>
        <div
          style={{ backgroundImage: `url(${bild})` }}
          className={css(styles.fluidImage)}
        />
        <Headline type="h5">{name}</Headline>
        <Headline type="h6">{funktion}</Headline>
      </div>
    );
  }
}

class PopUp extends React.Component {
  render() {
    const {
      bild,
      name,
      funktion,
      popupHeadline,
      popupSubline,
      popupText,
      popupBackground,
      popupTextColor,
    } = this.props.data;
    const { clear } = this.props;
    const color = popupTextColor;

    return (
      <div
        className={css(styles.popup, this.props.data && styles.popupActive)}
        style={{ background: popupBackground }}
      >
        <div className={css(styles.left)}>
          <div
            className={css(styles.image)}
            style={{
              backgroundImage: `url(${bild})`,
            }}
          />
          <Headline type="h5" style={{ color }}>
            {name}
          </Headline>
          <Headline type="h6" style={{ color }}>
            {funktion}
          </Headline>
        </div>
        <div className={css(styles.right)}>
          <div className={css(styles.close)} onClick={clear}>
            <div className={css(styles.closeLine)} />
            <div className={css(styles.closeLine)} />
          </div>
          <Headline type="h4" style={{ color }}>
            {popupHeadline}
          </Headline>
          <Headline type="h5" style={{ color }}>
            {popupSubline}
          </Headline>
          <Text style={{ color }}>{popupText}</Text>
        </div>
      </div>
    );
  }
}

export class Ansprechpartner extends React.Component {
  constructor(props) {
    super(props);
    this.slider = React.createRef();
  }

  state = {
    index: null,
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      className: "ansprechpartner-slider",
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 426,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    const data = this.props.data.person;
    return (
      <div className={css(styles.wrapper)}>
        <div className="container">
          <Helmet>
            <link
              rel="stylesheet"
              type="text/css"
              charset="UTF-8"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />
          </Helmet>
          <div className={css(styles.nav)}>
            <h2 className={css(styles.navHeadline)}>
              {this.props.data.headline}
            </h2>
          </div>
          <div className={css(styles.sliderWrapper)}>
          <div className={css(styles.arrows)}>
              <div
                className={css(styles.caret)}
                onClick={() => this.slider.current.slickPrev()}
              >
                <img
                  src={caret}
                  width={25}
                  style={{ transform: "rotate(90deg)" }}
                />
              </div>
              <div
                className={css(styles.caret)}
                onClick={() => this.slider.current.slickNext()}
              >
                <img
                  src={caret}
                  width={25}
                  style={{ transform: "rotate(-90deg)" }}
                />
              </div>
            </div>

            {data[this.state.index] && (
              <PopUp
                data={data[this.state.index]}
                clear={() => this.setState({ index: null })}
              />
            )}

            <Slider {...settings} ref={this.slider}>
              {data.map((item, index) => (
                <Person
                  data={item}
                  onClick={() => this.setState({ index })}
                  active={this.state.index === index}
                  index={index}
                  clear={() => this.setState({ index: null })}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  nav: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // borderBottom: "1px solid #003366",
    paddingBottom: 15,
    paddingTop: 15,
    marginBottom: 30,
  },
  navHeadline: { margin: 0, color: "rgb(0, 51, 102)" },
  swipe: {
    height: 200,
  },
  popup: {
    display: "none",
  },
  caret: {
    cursor: "pointer",
  },
  popupActive: {
    position: "absolute",
    zIndex: 10,
    top: 0,
    left: 0,
    width: "100%",
    background: "#00b7e5",
    display: "flex",
    padding: 24,
    paddingRight: 50,
    // borderRadius: 20,
    color: "#fff",
  },
  left: {
    display: "flex",
    flexDirection: "column",
    marginRight: 30,
  },
  image: {
    height: 220,
    width: 180,
    backgroundSize: "cover",
    // borderRadius: 20,
    marginBottom: 20,
  },
  fluidImage: {
    aspectRatio: "1 / 1.24",
    width: "100%",
    backgroundSize: "cover",
    // borderRadius: 20,
    marginBottom: 20,
  },
  name: {
    fontSize: 16,
    fontWeight: 600,
  },
  sliderWrapper: {
    position: "relative",
  },
  arrows: {
    position: "absolute",
    top: 0,
    left: -30,
    width: "calc(100% + 60px)",
    height: "calc(100% - 90px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [MOBILE]: {
      display: "none"
    },
  },
  headline: {
    fontWeight: 600,
    fontSize: 20,
  },
  close: {
    position: "absolute",
    right: 20,
    top: 15,
    width: 35,
    height: 35,
    cursor: "pointer",
  },
  closeLine: {
    height: 2,
    width: 25,
    background: "#fff",
    position: "absolute",
    left: 5,
    top: "calc(50% - 1px)",
    ":first-of-type": {
      transform: "rotate(45deg)"
    },
    ":last-of-type": {
      transform: "rotate(-45deg)"
    }
  },
  wrapper: {
    padding: "50px 12px",
    [MOBILE]: {
      padding: "25px 12px",
    },
  },
});
