// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import styles from './style.js'
import type { Props } from './types'

export default function Event(props: Props): React$Node {
  if (props.disabled) {
    return (
      <div className={css(styles.main)}>
        <div>
          <div className={css(styles.headline)}>An diesem Tag gibt es keine Events</div>
        </div>
      </div>
    )
  }

  return (
    <div className={css(styles.main)}>
      <div>
        <div className={css(styles.headline)}>{props.data.title.rendered}</div>
        <div className={css(styles.subline)}>{props.data.acf.ort.address}</div>
      </div>
      <div className={css(styles.mainRight)}>
        <div className={css(styles.date)}>{moment(props.data.acf.datum).format('L')}</div>
        <div className={css(styles.time)}>{props.data.acf.start}</div>
      </div>
    </div>
  )
}
