// @flow

import { LOCATION_CHANGE } from 'react-router-redux/lib/reducer'
import {
  LOAD_SINGLECONTACT_REQUEST,
  LOAD_SINGLECONTACT_SUCCESS,
  LOAD_SINGLECONTACT_FAILURE,
  LOAD_CONTACTS_REQUEST,
  LOAD_CONTACTS_SUCCESS,
  LOAD_CONTACTS_FAILURE,
  SET_SORT,
} from './actionTypes'
import type { State, Action } from './types'

const initialState: State = {
  loaded: false,
  data: [],
  singleData: [],
  sortBy: 'Alle Bereiche',
}

export const reducer = (state: State = initialState, { type, response }: Action): State => {
  switch (type) {
    case LOAD_CONTACTS_REQUEST:
      return { ...state, loaded: false }

    case LOAD_CONTACTS_SUCCESS:
      return { ...state, loaded: true, data: response }

    case LOAD_CONTACTS_FAILURE:
      return initialState

    case LOAD_SINGLECONTACT_SUCCESS:
      return { ...state, singleData: [response] }

    case LOAD_SINGLECONTACT_FAILURE:
      return initialState

    case SET_SORT:
      return { ...state, sortBy: response }

    case LOCATION_CHANGE:
      return { ...state, singleData: [] }

    default:
      return state
  }
}
