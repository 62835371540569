// @flow
import { StyleSheet } from 'aphrodite/no-important'

import { colors } from 'constants'
import { TABLET } from 'constants/breakpoints'

export default StyleSheet.create({
  sidebar: {
    [TABLET]: {
      marginTop: -1,
      border: '1px solid #D5DEE4',
    },
  },
  headlineActive: {
    [TABLET]: {
      background: colors.primary,
      color: colors.white,
    },
  },
  sidebarItem: {
    display: 'block',
    fontSize: 16,
    color: '#0E4F99',
    lineHeight: '32px',
    outline: 'none',
  },
  sidebarItemActive: {
    fontWeight: 500,
  },
  checkbox: {
    marginRight: 10,
  },
  content: {
    [TABLET]: {
      padding: '0 24px',
      maxHeight: 0,
      overflow: 'hidden',
    },
  },
  contentActive: {
    [TABLET]: {
      padding: '10px 24px',
      maxHeight: 'none',
    },
  },
  reset: {
    fontSize: 16,
    color: '#2771C6',
    outline: 'none',
    marginLeft: 20,
  },
  resetActive: {
    color: colors.white,
  },
  headline: {
    cursor: 'pointer',
    padding: '35px 0',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [TABLET]: {
      transition: 'all 300ms ease',
      padding: '20px 24px',
    },
  },
})
